import React from 'react';
import { Box, Container, Typography, Button, AppBar, Toolbar, Grid, Paper, List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import { Home as HomeIcon, Menu as MenuIcon } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const data = {
  labels: ['Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Today'],
  datasets: [
    {
      label: 'Cards Studied',
      data: [0, 0, 0, 0, 0, 1, 0],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const Dash = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Flashcards
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" startIcon={<HomeIcon />}>Home</Button>
          <Avatar alt="Profile Picture" src="/static/images/avatar/1.jpg" />
        </Toolbar>
      </AppBar> */}
      <Container component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">You are making progress</Typography>
              <Typography variant="body2">Only 3 cards to hit your daily target!</Typography>
              <Line data={data} options={options} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Typography variant="body2">Average: 0.1 cards</Typography>
                <Typography variant="body2">Daily Target: 4 cards</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Weekly Summary</Typography>
              <Typography variant="body2">Time Studied: 0 hrs 0 mins</Typography>
              <Typography variant="body2">Cards Studied: 1 card</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body2">Your flashcard performance</Typography>
              <List dense>
                {['Skip', 'Forgot', 'Partially Recalled', 'Recalled With Effort', 'Easily Recalled'].map((text, index) => (
                  <ListItem key={text}>
                    <ListItemIcon>
                      <Avatar>{text[0]}</Avatar>
                    </ListItemIcon>
                    <ListItemText primary={text} />
                    <Typography variant="body2">0%</Typography>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">No Priority</Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Biology" secondary="Due: 2" />
                  <Button variant="contained">Practice</Button>
                </ListItem>
                <ListItem>
                  <ListItemText primary="May 28th, 2024" secondary="Due: 1" />
                  <Button variant="contained">Practice</Button>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dash;
