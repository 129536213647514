import React, { useState } from 'react';
import JoditEditor from 'jodit-react';
import MathQuill from 'react-mathquill'; // For math input

const EnhancedEditor = () => {
  const [isMathMode, setIsMathMode] = useState(false);
  const [content, setContent] = useState('');
  const [mathContent, setMathContent] = useState('');

  const handleToggleMode = () => {
    setIsMathMode(!isMathMode);
  };

  const handleMathChange = (math) => {
    setMathContent(math);
  };

  return (
    <div>
      <button onClick={handleToggleMode}>
        {isMathMode ? 'Switch to Rich Text' : 'Switch to Math'}
      </button>
      {isMathMode ? (
        <MathQuill
          value={mathContent}
          onChange={handleMathChange}
        />
      ) : (
        <JoditEditor
          value={content}
          onChange={newContent => setContent(newContent)}
        />
      )}
    </div>
  );
};

export default EnhancedEditor;
