import React, { useState } from 'react';
import { Tooltip, IconButton, TextField, Box, Typography } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import folder from './assets/open-folder2 (1).png';
import { Height } from '@mui/icons-material';


const CreateFolderForm = ({ onCreateFolder, disableCreate }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim() === '') return;

    if (disableCreate) {
      setShowTooltip(true);
      return;
    }

    setShowTooltip(false);
    onCreateFolder({ name, type });
    setName('');
    setType('');
  };
  
  return (
    <Box >
      <Box display="flex" alignItems="center" >
       <img src={folder} style={{ width: 28, height: 28, marginRight: 8, marginLeft:-14.5 }} alt='folder'/>
        <Typography variant="body1" sx={{ mr: 10 }}>Create</Typography>
        <Tooltip title="Create Folder">
        <IconButton onClick={() => setType('folder')}>
          <span dangerouslySetInnerHTML={{ __html: '<i class="bi bi-folder-plus"></i>' }} />
        </IconButton>
        </Tooltip>
        <Tooltip title="Create Subfolder">
        <IconButton onClick={() => setType('subfolder')}>
          <span dangerouslySetInnerHTML={{ __html: '<i class="bi bi-journals"></i>' }} />
        </IconButton>
        </Tooltip>
      </Box>

      {type && (
        <form onSubmit={handleSubmit} >
          <TextField
            label="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
           
          />
          {showTooltip && <Typography color="error">Cannot create folders inside a subfolder.</Typography>}
        </form>
      )}
    </Box>
  );
};

export default CreateFolderForm;
