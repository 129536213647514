import React, { useState, useEffect, useRef } from 'react';
import { TextField, Paper, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Typography } from '@mui/material';
import { createPortal } from 'react-dom';
import axios from 'axios';

const TagModal = ({ open, position, onClose, subfolderId, cardId, cardQuestionType, onTagAdded }) => {
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState([]);
  const [editingTagId, setEditingTagId] = useState(null);
  const [editingTagValue, setEditingTagValue] = useState('');
  const modalRef = useRef();

  useEffect(() => {
    if (open) {
      setTag('');
      fetchTags();
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const fetchTags = async () => {
    try {
      const response = await axios.get(`http://warals1.ddns.net:9005/subfolder/${subfolderId}/tags/${cardQuestionType}/${cardId}/`);
      setTags(response.data.tags || []);
    } catch (error) {
      console.error('Failed to fetch tags from server', error);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleAddTag = async (event) => {
    if (event.key === 'Enter' && tag.trim()) {
      const payload = {
        tags: [{ name: tag }]
      };
      
      try {
        await axios.post(`http://warals1.ddns.net:9005/subfolder/${subfolderId}/tags/${cardQuestionType}/${cardId}/`, payload);
        onTagAdded(cardId, tag);
        fetchTags();
        setTag(''); // Clear the input field after adding a tag
        onClose(); // Close the modal after adding the tag
      } catch (error) {
        console.error('Failed to add tag to flashcard on server', error);
      }
    }
  };

  const handleEditTagStart = (tagId, currentTagName) => {
    setEditingTagId(tagId);
    setEditingTagValue(currentTagName);
  };

  const handleEditTagChange = (event) => {
    setEditingTagValue(event.target.value);
  };

  const handleEditTagSave = async () => {
    const payload = {
      tags: [{ name: editingTagValue }]
    };

    try {
      await axios.put(`http://warals1.ddns.net:9005/subfolder/${subfolderId}/tags/${cardQuestionType}/${cardId}/${editingTagId}/`, payload);
      setEditingTagId(null);
      fetchTags();
    } catch (error) {
      console.error('Failed to update tag on server', error);
    }
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await axios.delete(`http://warals1.ddns.net:9005/subfolder/${subfolderId}/tags/${cardQuestionType}/${cardId}/${tagId}/`);
      fetchTags();
    } catch (error) {
      console.error('Failed to delete tag from server', error);
    }
  };

  if (!open) return null;

  return createPortal(
    <Paper
      ref={modalRef}
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        zIndex: 1000,
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <TextField
        autoFocus
        margin="dense"
        variant="standard"
        label="Tag"
        type="text"
        fullWidth
        value={tag}
        onChange={(e) => setTag(e.target.value)}
        onKeyPress={handleAddTag}
      />
      <List>
        {tags.map((tagItem) => (
          <ListItem key={tagItem.id}>
            {editingTagId === tagItem.id ? (
              <>
                <TextField
                  value={editingTagValue}
                  onChange={handleEditTagChange}
                  onBlur={handleEditTagSave}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleEditTagSave();
                    }
                  }}
                  autoFocus
                  variant="standard"
                  fullWidth
                />
              </>
            ) : (
              <>
                <ListItemText primary={`🏷️${tagItem.name}`} sx={{ margin: 0 }} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditTagStart(tagItem.id, tagItem.name)}>
                    <Typography>✏️</Typography>
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTag(tagItem.id)}>
                    <Typography>🗑️</Typography>
                  </IconButton>
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Paper>,
    document.body
  );
};

export default TagModal;
