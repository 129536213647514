// practice cards page

import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardActions';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import skip from './assets/skip.png';
import forgot from './assets/forgot.png';
import partially from './assets/partially.png';
import efforts from './assets/efforts.png';
import convenient from './assets/convenient.png';
import { useParams } from 'react-router-dom';
import { StaticMathField } from 'react-mathquill';
import { useNavigate } from 'react-router-dom';



const FlipCard = styled(Card)(({ theme }) => ({
  width: '80%',
  height: '80%',
  perspective: 1000,
  position: 'relative',
  backgroundColor: 'white',
  borderRadius: '20px',
}));

const FlipCardInner = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'transform 0.8s',
  transformStyle: 'preserve-3d',
}));

const FlipCardFront = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  backgroundColor: 'white',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1627af',
  color: 'white',
  width: '100%',
  height: '60px',
  fontSize: '1.25rem',
  '&:hover': {
    backgroundColor: '#44a6e9',
  },
}));

const ImageButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Image = styled('img')(({ theme }) => ({
  width: '60px',
  height: '60px',
}));

export default function CardsDisplay() {
  const [flashcards, setFlashcards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const { subfolder_id } = useParams();
  const navigate = useNavigate(); 


  useEffect(() => {
    const fetchFlashcards = async () => {
      try {
        // Fetch all types of questions
        const singleLineResponse = await fetch(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/sub/`);
        const fillInTheBlanksResponse = await fetch(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/fib/`);
        const mcqResponse = await fetch(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/mcq/`);
        const trueFalseResponse = await fetch(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/`);
  
        const singleLineData = await singleLineResponse.json();
        const fillInTheBlanksData = await fillInTheBlanksResponse.json();
        const mcqData = await mcqResponse.json();
        const trueFalseData = await trueFalseResponse.json();
  
        // Map the MCQ data to include only the correct answers
        const processedMcqData = mcqData.map(mcq => ({
          ...mcq,
          answers: mcq.answers.find(answer => answer.is_correct)?.answer_text || '',
        }));
  
        // Combine all question types into one array
        const allFlashcards = [
          ...singleLineData,
          ...fillInTheBlanksData,
          ...processedMcqData,
          ...trueFalseData,
        ];
  
        setFlashcards(allFlashcards);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchFlashcards();
  }, [subfolder_id]);
  

  const handleFlip = () => {
    setShowAnswer(!showAnswer);
  };

  const handleNext = async (feedback) => {
    if (currentIndex < flashcards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
    setShowAnswer(false);

    try {
      const questionId = flashcards[currentIndex].id;
      const question_type = flashcards[currentIndex].question_type;
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/subfolder/106/feedbacks/${question_type}/${questionId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback }),
      });
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  const handleClose = () => {
    setShowAnswer(false);
    navigate('/home');
  };

  const renderQuestionContent = () => {
    const currentFlashcard = flashcards[currentIndex];
  
    if (!currentFlashcard) {
      return <Typography>Loading question...</Typography>; // Handle undefined state
    }
  
    switch (currentFlashcard.question_type) {
      case 'fillups':
        return <StaticMathField>{currentFlashcard.fillups_question}</StaticMathField>;
      case 'mcq':
        return <StaticMathField>{currentFlashcard.mcq_question}</StaticMathField>;
      case 'true_false':
        return <Typography>{currentFlashcard.true_false_statement}</Typography>;
      default:
        return <StaticMathField>{currentFlashcard.statement}</StaticMathField>; // Default to single-line questions
    }
  };
  

  // if (flashcards.length === 0) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(to right, #142be1, #44a6e9)',
      }}
    >
      <FlipCard>
        <FlipCardInner>
          <FlipCardFront>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', p: 1 }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h4" component="div">
              <strong>Question:</strong> {renderQuestionContent()}
            </Typography>
            {showAnswer && (
              <Typography variant="h4" component="div" sx={{ textAlign: 'center', mt: 2 }}>
                <strong>Answer:</strong> <StaticMathField>{flashcards[currentIndex].answers}</StaticMathField>
              </Typography>
            )}
            <CardActions sx={{ width: '100%' }}>
              {!showAnswer && (
                <StyledButton
                  variant="contained"
                  onClick={handleFlip}
                  startIcon={<VisibilityIcon />}
                >
                  Show Answer
                </StyledButton>
              )}
            </CardActions>
            {showAnswer && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <ImageButton onClick={() => handleNext('skip')}>
                    <Image src={skip} alt="skip" />
                    <Typography variant="caption">Skip</Typography>
                  </ImageButton>
                  <ImageButton onClick={() => handleNext('forgot')}>
                    <Image src={forgot} alt="forgot" />
                    <Typography variant="caption">Forgot</Typography>
                  </ImageButton>
                  <ImageButton onClick={() => handleNext('partially recalled')}>
                    <Image src={partially} alt="partially" />
                    <Typography variant="caption">Partially Recalled</Typography>
                  </ImageButton>
                  <ImageButton onClick={() => handleNext('recalled with efforts')}>
                    <Image src={efforts} alt="efforts" />
                    <Typography variant="caption">Recalled with Efforts</Typography>
                  </ImageButton>
                  <ImageButton onClick={() => handleNext('easily recalled')}>
                    <Image src={convenient} alt="convenient" />
                    <Typography variant="caption">Easily Recalled</Typography>
                  </ImageButton>
                </Box>
              </Box>
            )}
          </FlipCardFront>
        </FlipCardInner>
      </FlipCard>
    </Box>
  );
}