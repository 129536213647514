import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const ProfileSettings = () => {
    const [isEmailModalOpen, setEmailModalOpen] = useState(false);
    const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);

let navigate = useNavigate();

    const openEmailModal = () => {
        setEmailModalOpen(true);
    };

    const closeEmailModal = () => {
        setEmailModalOpen(false);
    };

    const openPasswordModal = () => {
        setPasswordModalOpen(true);
    };

    const closePasswordModal = () => {
        setPasswordModalOpen(false);
    };

    const handleEditClick = () => {
         navigate('/profilepage')
    }

    return (
        <div style={styles.container}>
            {/* Header */}
            <div style={styles.header}>
                <h2>Profile</h2>
                <button style={styles.closeButton}>✕</button>
            </div>

            {/* Profile Section */}
            <div style={styles.profileSection}>
                <div style={styles.profileInfo}>
                    <img 
                        src="https://via.placeholder.com/100" 
                        alt="Profile" 
                        style={styles.profileImage} 
                    />
                    <div style={styles.profileText}>
                        <h3 style={styles.profileName}>Guest</h3>
                        <p style={styles.profileHandle}>@shweta4866470</p>
                    </div>
                </div>
                <div style={styles.profileButtons}>
                    <button style={styles.sharedButton}>🌐 Shared Profile</button>
                    <button onClick={handleEditClick} style={styles.editButton}>✏️ Edit Profile</button>
                </div>
            </div>

            {/* Email Address */}
            <div style={styles.section}>
                <p style={styles.sectionTitle}>Email Address</p>
                <div style={styles.sectionContent}>
                    <span style={styles.sectionText}>shwetasharma8538@gmail.com</span>
                    <button style={styles.sectionButton} onClick={openEmailModal}>Change Email</button>
                </div>
            </div>

            {/* Other Sections */}
            <div style={styles.section}>
                <p style={styles.sectionTitle}>Password</p>
                <div style={styles.sectionContent}>
                    <span style={styles.sectionText}>Password login is disabled, log in through Google or Apple.</span>
                    <button style={styles.sectionButton} onClick={openPasswordModal}>Set Password</button>
                </div>
            </div>

            <div style={styles.section}>
                <p style={styles.sectionTitle}>Login Methods</p>
                <div style={styles.sectionContent}>
                    <span style={styles.sectionText}>Manage your login preferences.</span>
                    <button style={styles.sectionButton}> Add Apple Login</button>
                </div>
            </div>

            <div style={styles.section}>
                <p style={styles.sectionTitle}>Syncing</p>
                <div style={styles.sectionContent}>
                    <span style={styles.sectionText}>Last synced a few seconds ago.</span>
                    <button style={styles.sectionButton}>⟳ Sync Now</button>
                </div>
            </div>

            {/* Change Email Modal */}
            {isEmailModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modal}>
                        <h3>Change Email</h3>
                        <div style={styles.formGroup}>
                            <label htmlFor="newEmail" style={styles.label}>New Email</label>
                            <input type="email" id="newEmail" placeholder="New Email" style={styles.inputField} />
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="password" style={styles.label}>Password</label>
                            <input type="password" id="password" placeholder="Password" style={styles.inputField} />
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="confirmPassword" style={styles.label}>Confirm Password</label>
                            <input type="password" id="confirmPassword" placeholder="Confirm Password" style={styles.inputField} />
                        </div>
                        <div style={styles.modalButtons}>
                            <button style={styles.saveButton}>Save</button>
                            <button style={styles.cancelButton} onClick={closeEmailModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Change Password Modal */}
            {isPasswordModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modal}>
                        <h3>Set New Password</h3>
                        <div style={styles.formGroup}>
                            <label htmlFor="newPassword" style={styles.label}>New Password</label>
                            <input type="password" id="newPassword" placeholder="New Password" style={styles.inputField} />
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="confirmNewPassword" style={styles.label}>Confirm New Password</label>
                            <input type="password" id="confirmNewPassword" placeholder="Confirm New Password" style={styles.inputField} />
                        </div>
                        <div style={styles.modalButtons}>
                            <button style={styles.saveButton}>Save</button>
                            <button style={styles.cancelButton} onClick={closePasswordModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        width: '400px',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
        justifyContent:'center',
        display:'flex',
        flexDirection:'column',
        // alignItems:'center'
        
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    closeButton: {
        background: 'none',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
    },
    profileSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    profileInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    profileImage: {
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        marginRight: '15px',
    },
    profileText: {
        display: 'flex',
        flexDirection: 'column',
    },
    profileName: {
        margin: '0',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    profileHandle: {
        margin: '0',
        color: '#888',
        fontSize: '14px',
    },
    profileButtons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    sharedButton: {
        backgroundColor: '#f5f5f5',
        border: '1px solid #ddd',
        borderRadius: '5px',
        padding: '5px 10px',
        marginBottom: '5px',
        cursor: 'pointer',
        fontSize: '12px',
    },
    editButton: {
        backgroundColor: '#142be1',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
        cursor: 'pointer',
        fontSize: '12px',
    },
    section: {
        marginBottom: '20px',
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    sectionContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sectionText: {
        color: '#555',
        fontSize: '14px',
    },
    sectionButton: {
        backgroundColor: '#f5f5f5',
        border: '1px solid #ddd',
        borderRadius: '5px',
        padding: '5px 10px',
        cursor: 'pointer',
        fontSize: '12px',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modal: {
        width: '300px',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight
        : 'bold',
        fontSize: '14px',
        color: '#333',
        },
        inputField: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        fontSize: '14px',
        },
        modalButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        },
        saveButton: {
        backgroundColor: '#142be1',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '14px',
        },
        cancelButton: {
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '14px',
        },
        };
        
        export default ProfileSettings;