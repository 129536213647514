import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Box, Grid, CssBaseline, Paper, Modal, Backdrop, Fade } from '@mui/material';
import { Google as GoogleIcon, Apple as AppleIcon } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false); // State to control modal
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(''); // State for forgot password email
  const [forgotPasswordError, setForgotPasswordError] = useState(''); // Error for forgot password
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(''); // Success message for forgot password

  let navigate = useNavigate();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
      background: {
        default: '#ffffff',
      },
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    // Validate email and password fields before proceeding
    if (!email || !password) {
      alert('Please enter both email and password.');
      return;
    }
  
    try {
      // Attempt to log in
      const response = await axios.post('http://warals1.ddns.net:9005/login/', {
        email,
        password,
      });
  
      if (response.status === 200 ) {
        // On successful login, store the token and navigate
        sessionStorage.setItem('authToken', response.data.token);
        console.log('Login Successful:', response.data);
        navigate('/home');
      } else {
        // Handle unexpected response structure
        console.error('Unexpected login response:', response);
        alert('Login failed. Please try again later.');
      }
    } catch (error) {
      // Handle error scenarios with detailed feedback
      if (error.response) {
        // Server responded with a status outside the 2xx range
        console.error('Server Error:', error.response.data);
        alert(error.response.data?.message || 'Invalid email or password.');
      } else if (error.request) {
        // Request was made but no response received
        console.error('No response from server:', error.request);
        alert('Unable to connect to the server. Please check your internet connection.');
      } else {
        // Other errors (client-side or unknown issues)
        console.error('Error:', error.message);
        alert('An error occurred. Please try again later.');
      }
    }
  };
  

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('http://warals1.ddns.net:9005/password-reset/', {
        email: forgotPasswordEmail,
      });

      console.log('Password Reset Email Sent:', response.data);
      setForgotPasswordSuccess('Password reset email sent successfully.'); // Success message
      setForgotPasswordError(''); // Clear error
    } catch (error) {
      console.error('Password Reset Failed:', error);
      setForgotPasswordError('Failed to send password reset email.'); // Display error message
      setForgotPasswordSuccess(''); // Clear success message
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm"> 
        <Paper elevation={3} style={{ padding: '20px', marginTop: '50px', minHeight: '60vh' }}> 
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            onSubmit={handleSubmit}
          >
            <img src="/path/to/your/logo.png" alt="Logo" style={{ marginBottom: 20 }} />
            <Typography variant="h5" component="h1" gutterBottom>
              Sign into RemNote
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<GoogleIcon />}
              style={{ marginBottom: 10 }}
            >
              Continue with Google
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<AppleIcon />}
              style={{ marginBottom: 10 }}
            >
              Continue with Apple
            </Button>
            <Typography variant="body2" color="textSecondary" align="center" style={{ margin: '20px 0' }}>
              Or sign in with email
            </Typography>
            {error && (
              <Typography variant="body2" color="error" align="center" style={{ marginBottom: 10 }}>
                {error}
              </Typography>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
            >
              Sign In
            </Button>
            <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  New to RemNote? <a href="/signup">Create an account.</a>
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: 10 }}>
              <span style={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => setOpenForgotPasswordModal(true)}>
                Forgot password?
              </span>
            </Typography>
          </Box>
        </Paper>
      </Container>

      {/* Forgot Password Modal */}
      <Modal
        aria-labelledby="forgot-password-modal-title"
        aria-describedby="forgot-password-modal-description"
        open={openForgotPasswordModal}
        onClose={() => setOpenForgotPasswordModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openForgotPasswordModal}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="background.paper"
            border="2px solid #000"
            boxShadow={24}
            p={4}
            style={{ margin: 'auto', maxWidth: 400, outline: 'none' }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Forgot Password
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: 20 }}>
              Enter your registered email to receive a password reset link.
            </Typography>
            {forgotPasswordError && (
              <Typography variant="body2" color="error" align="center" style={{ marginBottom: 10 }}>
                {forgotPasswordError}
              </Typography>
            )}
            {forgotPasswordSuccess && (
              <Typography variant="body2" color="primary" align="center" style={{ marginBottom: 10 }}>
                {forgotPasswordSuccess}
              </Typography>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="forgot-password-email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={handleForgotPasswordSubmit}
            >
              Send Reset Link
            </Button>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default Login;
