import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TagModal from './components/TagModal';
import { addStyles, EditableMathField } from 'react-mathquill';
import { StaticMathField } from 'react-mathquill';
import StyleIcon from '@mui/icons-material/Style';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import sanitizeHtml from 'sanitize-html';

// Import the Clipboard module from Quill
const Clipboard = Quill.import("modules/clipboard");

class CustomClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const html = clipboardData.getData("text/html");

    if (html) {
      const sanitizedHtml = sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img"
        ]),
        allowedAttributes: false, // Strip all attributes except essential ones
      });

      // Insert the HTML at the cursor position
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, sanitizedHtml);
      }
    } else {
      const text = clipboardData.getData("text/plain");
      // Insert plain text as HTML with line breaks
      const formattedText = `<p>${text.replace(/\n/g, "<br>")}</p>`;
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, formattedText);
      }
    }
  }
}



// Register the custom clipboard
Quill.register("modules/clipboard", CustomClipboard);



addStyles();

function App1() {
  const [cards, setCards] = useState([]);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['']);
  const [correctOption, setCorrectOption] = useState(null);
  const [currentExplanation, setCurrentExplanation] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const optionRefs = useRef([]);
  const { subfolder_id } = useParams();
  const [activeOptionIndex, setActiveOptionIndex] = useState(null);
  
  // Edit Modal States
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCardIndex, setEditCardIndex] = useState(null);
  const [editQuestion, setEditQuestion] = useState('');
  const [editOptions, setEditOptions] = useState(['']);
  const [editCorrectOption, setEditCorrectOption] = useState(null);
  const [editExplanation, setEditExplanation] = useState('');

  // Delete Dialog States
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(null);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagCardIndex, setTagCardIndex] = useState(null);
  const [tagModalPosition, setTagModalPosition] = useState({ top: 0, left: 0 });
  const navigate = useNavigate();

  // Popover Editor States
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const [editingField, setEditingField] = useState(null); // 'question' or 'explanation'
  const [selectionRange, setSelectionRange] = useState(null);

  

   // Refs to ReactQuill editors
   const questionQuillRef = useRef(null);
   const explanationQuillRef = useRef(null);
   
  const [dropdownVisible, setDropdownVisible] = useState(false); // Controls dropdown visibility
  const [selectedOption, setSelectedOption] = useState(''); // State to track selected option

  useEffect(() => {
    if (subfolder_id) {
      fetchFlashcardsFromServer();
    }
  }, [subfolder_id]);

  const fetchFlashcardsFromServer = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.get(`${apiUrl}/subfolder/${subfolder_id}/mcq/`);
      const fetchedCards = response.data.map(item => {
        const correctIndex = item.answers.findIndex(answer => answer.is_correct);
        return {
          id: item.id,
          question: item.statement,
          question_type: item.question_type,
          options: item.answers.map(answer => answer.answer_text),
          correctOption: correctIndex,
          explanation: item.explanation,
          expanded: false,
          tags: item.tags // Store the entire tags array
        };
      });
      setCards(fetchedCards);
    } catch (error) {
      console.error('Failed to fetch flashcards from server', error);
    }
  };

  useEffect(() => {
    optionRefs.current = optionRefs.current.slice(0, options.length);
  }, [options]);

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value; // Update the specific option by index
    setOptions(newOptions);
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);

    if (correctOption === index) {
      setCorrectOption(null);
    } else if (correctOption > index) {
      setCorrectOption(correctOption - 1);
    }
  };

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleOptionsSelect = (index) => {
    const textArea = document.getElementById(`optionTextField_${index}`);
    if (textArea) {
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      const selected = textArea.value.substring(start, end);
      setSelectedText(selected);
    }
  };

  const handleTextSelect = () => {
    const textArea = document.getElementById('questionTextField');
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const selected = textArea.value.substring(start, end);
    setSelectedText(selected);
  };

  const handleExplanationSelect = () => {
    const textArea = document.getElementById('explanationTextField');
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const selected = textArea.value.substring(start, end);
    setSelectedText(selected);
  };

  const renderContent = (html) => {
    if (!html) return null;
  
    // Split content into LaTeX and non-LaTeX parts
    const parts = html.split(/(\$\$.+?\$\$|\$.+?\$)/g);
  
    return (
      <div>
        {parts.map((part, index) => {
          // Handle block-level LaTeX ($$...$$)
          if (part.startsWith("$$") && part.endsWith("$$")) {
            const latex = part.slice(2, -2); // Remove $$ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: true });
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content block-latex"
                />
              );
            } catch (error) {
              console.error("Block LaTeX rendering error:", error);
              return <div key={index} className="error-content">{part}</div>;
            }
          }
  
          // Handle inline LaTeX ($...$)
          if (part.startsWith("$") && part.endsWith("$")) {
            const latex = part.slice(1, -1); // Remove $ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: false });
              return (
                <span
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content inline-latex"
                />
              );
            } catch (error) {
              console.error("Inline LaTeX rendering error:", error);
              return <span key={index} className="error-content">{part}</span>;
            }
          }
  
          // Handle non-LaTeX content
          try {
            const formattedContent = part.replace(/\n/g, "<br />"); // Replace newlines
            const allowedTags = ["b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img"];
            const allowedAttributes = {
              img: ["src", "alt"],
              a: ["href", "target"],
              span: ["style"],
              p: ["style"],
            };
  
            // Use sanitize-html or DOMPurify with selective sanitization
            const sanitizedContent = sanitizeHtml(formattedContent, {
              allowedTags,
              allowedAttributes,
              transformTags: {
                'p': 'span', // Convert <p> tags to <span>
                'div': 'span', // Convert <div> tags to <span>
              },
            });
  
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                className="rendered-content inline-text"
              />
            );
          } catch (error) {
            console.error("HTML rendering error:", error);
            return <span key={index} className="error-content">{part}</span>;
          }
        })}
      </div>
    );
  };
  

  const handleSaveQuestion = async () => {
    if (question && options.every(opt => opt) && correctOption !== null && currentExplanation) {
      const payload = {
        statement: question,
        created_by: "Emily Green",
        question_type: "MCQ",
        explanation: currentExplanation,
        answers: options.map((option, index) => ({
          answer_text: option,
          is_correct: index === correctOption
        })),
        tags: []
      };

      try {
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const response = await axios.post(`${apiUrl}/subfolder/${subfolder_id}/mcq/`, payload);
        if (response.status === 201) {
          fetchFlashcardsFromServer();
          setQuestion('');
          setOptions(['']);
          setCorrectOption(null);
          setCurrentExplanation('');
        } else {
          alert('Failed to save the question. Please try again.');
        }
      } catch (error) {
        console.error('Error saving question:', error);
        alert('Failed to save the question. Please try again.');
      }
    } else {
      alert('Please complete the question, options, mark the correct option, and provide an explanation.');
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (index === -1) {
        optionRefs.current[0]?.focus();
      } else if (index === options.length - 1) {
        setOptions([...options, '']);
        setTimeout(() => {
          optionRefs.current[options.length]?.focus();
        }, 0);
      } else {
        optionRefs.current[index + 1]?.focus();
      }
    }
  };

  const toggleCardExpand = (index) => {
    const newCards = [...cards];
    newCards[index].expanded = !newCards[index].expanded;
    setCards(newCards);
  };

  // Edit Modal Functions
  const openEditModal = (index) => {
    setEditCardIndex(index);
    setEditQuestion(cards[index].question);
    setEditOptions([...cards[index].options]);
    setEditCorrectOption(cards[index].correctOption);
    setEditExplanation(cards[index].explanation);
    setEditModalOpen(true);
  };

  const handleEditOptionChange = (index, value) => {
    const newOptions = [...editOptions];
    newOptions[index] = value;
    setEditOptions(newOptions);
  };

  const handleEditRemoveOption = (index) => {
    const newOptions = [...editOptions];
    newOptions.splice(index, 1);
    setEditOptions(newOptions);

    if (editCorrectOption === index) {
      setEditCorrectOption(null);
    } else if (editCorrectOption > index) {
      setEditCorrectOption(editCorrectOption - 1);
    }
  };

  const addEditOption = () => {
    setEditOptions([...editOptions, '']);
  };

  const handleUpdateCard = async () => {
    if (editQuestion && editOptions.every(opt => opt) && editCorrectOption !== null && editExplanation) {
      const updatedCard = {
        statement: editQuestion,
        created_by: 'Emily Green',
        question_type: 'MCQ',
        explanation: editExplanation,
        answers: editOptions.map((option, index) => ({
          answer_text: option,
          is_correct: index === editCorrectOption
        })),
        tags: cards[editCardIndex].tags // Preserve existing tags
      };

      try {
        const cardId = cards[editCardIndex].id;
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const response = await axios.put(`${apiUrl}/subfolder/${subfolder_id}/mcq/${cardId}/`, updatedCard);

        if (response.status === 200) {
          fetchFlashcardsFromServer();
          setEditModalOpen(false);
        } else {
          alert('Failed to update the flashcard. Please try again.');
        }
      } catch (error) {
        console.error('Failed to update flashcard on server', error);
        alert('Failed to update the flashcard. Please try again.');
      }
    } else {
      alert('Please complete all fields before saving.');
    }
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  // Delete Dialog Functions
  const openDeleteDialog = (id) => {
    setDeleteCardId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCard = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.delete(`${apiUrl}/subfolder/${subfolder_id}/mcq/${deleteCardId}/`);
      if (response.status === 200) {
        fetchFlashcardsFromServer();
        setDeleteDialogOpen(false);
        alert('Flashcard successfully deleted.');
      } else {
        alert('Failed to delete the flashcard. Please try again.');
      }
    } catch (error) {
      console.error('Failed to delete flashcard from server', error);
      alert('Failed to delete the flashcard. Please try again.');
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Tag Modal Functions
  const openTagModal = (index, event) => {
    const iconRect = event.target.getBoundingClientRect();
    setTagCardIndex(index);
    setTagModalPosition({
      top: iconRect.bottom + window.scrollY,
      left: iconRect.left + window.scrollX,
    });
    setTagModalOpen(true);
  };

  const handleTagAdded = (cardId, newTag) => {
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        return { ...card, tags: [...card.tags, newTag] };
      }
      return card;
    });
    setCards(updatedCards);
  };

  // const handleIconClick = () => {
  //   //const hardCodedText = "mcqs"; // replace with your hard-coded text
  //   navigate(`/practiceparticularcard/${subfolder_id}/mcqs`);
  // };

  const handleIconClick = () => {
    // Toggle dropdown visibility when icon is clicked
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    
    if (selectedValue === 'practiceCard') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "mcqs";
      navigate(

          `/practiceparticularcard/${subfolder_id}/mcqs`      // Example: history.push('/practice-card');
    )}
    else if (selectedValue === 'spacedrep') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "fib";
      navigate(
        
        `/practicespacedrep/${subfolder_id}/${encodeURIComponent(
           hardCodedText
          )}`      // Example: history.push('/practice-card');
  )}

    // Hide dropdown after selection
    setDropdownVisible(false);
  };

  return (
    <div className="App">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1><b>Multiple Choice Questions</b></h1>
        <div style={{ position: 'relative' }}>
  <StyleIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} />

  {/* Options (only visible when dropdownVisible is true) */}
  {dropdownVisible && (
    <div
      style={{
        position: 'absolute',
        top: '30px', // Adjust position based on your layout
        right: '-30px', // Adjusted to move it further to the right
        zIndex: 1,
        backgroundColor: '#e3f2fd', // Light bluish shade
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '0', // Remove extra padding here
      }}
    >
      <div
        onClick={() => handleDropdownChange({ target: { value: 'practiceCard' } })}
        style={{
          cursor: 'pointer',
          padding: '10px 15px', // Increased padding for better coverage
          transition: 'background-color 0.3s ease',
          whiteSpace: 'nowrap', // Prevent text from wrapping
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
      >
        Practice FlashCard
      </div>
      <div
        onClick={() => handleDropdownChange({ target: { value: 'spacedrep' } })}
        style={{
          cursor: 'pointer',
          padding: '10px 15px', // Increased padding for better coverage
          transition: 'background-color 0.3s ease',
          whiteSpace: 'nowrap', // Prevent text from wrapping
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
      >
        Practice with Spaced Repetition
      </div>
    </div>
  )}
</div>
  
 </div>
      
      <div style={{ position: 'relative', marginBottom: '20px', wordBreak: 'break-word', overflowWrap: 'break-word' }}>

      <ReactQuill
          ref={questionQuillRef}
          value={question}
          onChange={setQuestion}
          placeholder="Enter your question here"
          theme="snow"
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              [{ 'direction': 'rtl' }],                         // text direction
              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['clean']                                         // remove formatting button
            ]
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
            'list', 'bullet', 'indent',
            'link', 'image', 'video',
            'color', 'background', 'align',
            'script'
          ]}
          InputProps={{
            style: {
              wordBreak: 'break-word',  // Ensures long words wrap at boundaries
              overflowWrap: 'break-word', // Forces wrap even if no spaces are present
              whiteSpace: 'pre-wrap',   // Preserves line breaks and spaces as entered
            },
          }}
          style={{
            wordBreak: 'break-word',  // Same applied at outer level
            overflowWrap: 'break-word', // Ensures long words break properly
            whiteSpace: 'pre-wrap',   // Preserves newlines and spaces
          }}
        />
      
      
      <div className="mt-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>

          {renderContent(question)}
        </div>
      </div>

      <div>
            {options.map((option, index) => (
                 <div key={index} style={{ position: 'relative', marginBottom: '10px', wordBreak: 'break-word', overflowWrap: 'break-word' }}>

                    <input
                        type="text"
                        id={`optionTextField_${index}`}
                        value={option}
                        onChange={(e) => handleOptionChange(index, e.target.value)}
                        onMouseUp={() => setActiveOptionIndex(index)}
                        onKeyDown={(e) => handleKeyDown(e, index)} // Handling Enter key to add more options
                        placeholder="Enter your option here"
                        style={{
                          border: 'none',
                          borderBottom: '1px solid #ccc',
                          outline: 'none',
                          textAlign: 'left',
                          borderRadius: '2px',
                          width: '80%',
                          paddingLeft: '10px',
                          wordBreak: 'break-word',  // Ensures long words wrap at boundaries
                          overflowWrap: 'break-word', // Forces wrap even if no spaces are present
                          whiteSpace: 'pre-wrap',   // Preserves line breaks and spaces as entered
                        }}
                      />
                  
                    <label style={{ marginLeft: '20px' }}>
                        <input
                            type="radio"
                            name="correctOption"
                            checked={correctOption === index}
                            onChange={() => setCorrectOption(index)}
                        />
                    </label>
                    <IconButton aria-label="delete" onClick={() => handleRemoveOption(index)}>
                        <DeleteIcon />
                    </IconButton>

                    {activeOptionIndex === index && (
                        <div className="mt-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>

                            {renderContent(option)} {/* Display the option content */}
                        </div>
                    )}
                </div>
            ))}
        {/* Add Option Button */}
        {/* <Button onClick={handleAddOption} variant="contained" color="primary">
          Add Option
        </Button> */}
      </div>

      {/* Explanation Field */}
      <div style={{ marginTop: '20px', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
        <ReactQuill
          ref={explanationQuillRef}
          value={currentExplanation}
          onChange={setCurrentExplanation}
          placeholder="Enter your explanation here"
          theme="snow"
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              [{ 'direction': 'rtl' }],                         // text direction
              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['clean']                                         // remove formatting button
            ]
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
            'list', 'bullet', 'indent',
            'link', 'image', 'video',
            'color', 'background', 'align',
            'script'
          ]}
          InputProps={{
            style: {
              wordBreak: 'break-word',  // Ensures long words wrap at boundaries
              overflowWrap: 'break-word', // Forces wrap even if no spaces are present
              whiteSpace: 'pre-wrap',   // Preserves line breaks and spaces as entered
            },
          }}
          style={{
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        />
      
      <div className="mt-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>

          {renderContent(currentExplanation)}
        </div>
      </div>

      <Button onClick={handleSaveQuestion} variant="contained"   sx={{ backgroundColor: '#1565c0', color: 'white', '&:hover': { backgroundColor: '#0d47a1' }, marginTop: '20px' }}
      >
        Save Question
      </Button>

      <div>
  <ul className="card-list">
    {cards.map((card, index) => (
      <li
      key={index}
      className="card"
      style={{
        background: 'linear-gradient(to right, #dfe6f9, rgb(124 182 246))',
        position: 'relative',
        padding: '16px',
        borderRadius: '8px',
        marginBottom: '12px',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        maxWidth: 'calc(100% - 80px)', // Add maxWidth for controlled width
        whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
      }}
    >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            paddingRight: '40px', // Adjust padding if needed
            maxWidth: 'calc(100% - 70px)',
            whiteSpace: 'pre-wrap'
          }}
        >
          <strong style={{ marginRight: '2px' }}>Q:</strong>
          <span style={{ flex: 1 }}><strong>{renderContent(card.question)}</strong></span>
        </div>

        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <i
            className={`bi ${card.expanded ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand'}`}
            title={card.expanded ? 'Contract' : 'Expand'}
            onClick={() => toggleCardExpand(index)}
          ></i>
          <i className="bi bi-pencil" title="Edit" onClick={() => openEditModal(index)}></i>
          <i className="bi bi-trash" title="Delete" onClick={() => openDeleteDialog(card.id)}></i>
          <i className="bi bi-tag" title="Tag" onClick={(e) => openTagModal(index, e)}></i>
        </div>
        {card.expanded && (
  <>
    <p style={{ margin: 0, padding: 0 }}>
      <strong>Options:</strong>
    </p>
    <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
  {card.options.map((option, i) => (
    <li
      key={i}
      style={{
        fontWeight: card.correctOption === i ? 'bold' : 'normal',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        margin: 0,
        padding: 0,
        lineHeight: '1.2',
      }}
    >
      {renderContent(`${i + 1}. ${option}`)}
    </li>
  ))}
</ul>
    
            {card.explanation && (
                  <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
                  }}
                >
                   <strong style={{ marginRight: '5px' }}>Explanation:</strong>
                   <span style={{ flex: 1 }}>{renderContent(card.explanation)}</span>
                  </div>
                  )}
            <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
              <p>
                Tags:
                {card.tags.map((tag, index) => (
                  <span key={tag.id}>
                    [{tag.name}{index < card.tags.length - 1 ? ', ' : ''}]
                  </span>
                ))}
              </p>
            </div>
          </>
        )}
      </li>
    ))}
  </ul>
</div>

      {/* Edit Modal */}
      <Dialog open={editModalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>Edit Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit your flashcard details below.</DialogContentText>

          {/* Editable Question Field */}
          <div style={{ marginBottom: '20px' }}>
            <label>Question</label>
            <ReactQuill
              value={editQuestion}
              onChange={setEditQuestion}
              placeholder="Enter your question here"
              theme="snow"
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'script': 'sub'}, { 'script': 'super' }],
                  [{ 'indent': '-1'}, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
                'list', 'bullet', 'indent',
                'link', 'image', 'video',
                'color', 'background', 'align',
                'script'
              ]}
            />
          </div>

          {/* Editable Options */}
          <div>
            <label>Options:</label>
            {editOptions.map((option, index) => (
              <div key={index} style={{ position: 'relative', marginBottom: '10px' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    pointerEvents: 'none',
                    color: '#999',
                    fontSize: '16px',
                    zIndex: 1,
                    display: option ? 'none' : 'block',
                  }}
                >
                  Enter option {index + 1}
                </div>
                <TextField
                  value={option}
                  onChange={(e) => handleEditOptionChange(index, e.target.value)}
                  style={{
                    border: 'none',
                    borderBottom: '1px solid #ccc',
                    outline: 'none',
                    textAlign: 'left',
                    borderRadius: '2px',
                    width: '80%',
                    paddingLeft: '10px',
                  }}
                  variant="outlined"
                />
                <IconButton aria-label="delete" onClick={() => handleEditRemoveOption(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button onClick={addEditOption} variant="contained" color="primary">
              Add Option
            </Button>
          </div>

          {/* Editable Explanation Field */}
          <div style={{ marginTop: '20px' }}>
            <label>Explanation</label>
            <ReactQuill
              value={editExplanation}
              onChange={setEditExplanation}
              placeholder="Enter your explanation here"
              theme="snow"
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'script': 'sub'}, { 'script': 'super' }],
                  [{ 'indent': '-1'}, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
                'list', 'bullet', 'indent',
                'link', 'image', 'video',
                'color', 'background', 'align',
                'script'
              ]}
            />
          </div>
          {/* Correct Option Selection */}
          <div style={{ marginTop: '20px' }}>
            <label>Correct Option:</label>
            {editOptions.map((_, index) => (
              <label key={index} style={{ marginLeft: '10px' }}>
                <input
                  type="radio"
                  name="editCorrectOption"
                  checked={editCorrectOption === index}
                  onChange={() => setEditCorrectOption(index)}
                />
                {index + 1}
              </label>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateCard} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this flashcard?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCard} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Tag Modal */}
      <TagModal
        open={tagModalOpen}
        position={tagModalPosition}
        onClose={() => setTagModalOpen(false)}
        subfolderId={subfolder_id}
        cardId={cards[tagCardIndex]?.id}
        cardQuestionType={cards[tagCardIndex]?.question_type}
        onTagAdded={handleTagAdded}
      />
    </div>
  );
}

export default App1;
