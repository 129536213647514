import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Box, Grid, CssBaseline, Paper, Modal, Backdrop, Fade } from '@mui/material';
import { Google as GoogleIcon, Apple as AppleIcon } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [otp, setOtp] = useState(''); // State for OTP
  const [error, setError] = useState('');
  const [openOtpModal, setOpenOtpModal] = useState(false); // State for modal open/close

  let navigate = useNavigate();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
      background: {
        default: '#ffffff',
      },
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('http://warals1.ddns.net:9005/register/', {
        email,
        first_name,
        last_name,
        password,
        password2,
      });

      console.log('Signup Successful:', response.data);
      setOpenOtpModal(true); // Open OTP modal after signup
    } catch (error) {
      console.error('Signup Failed:', error);
      setError('Signup failed. Please try again.'); // Display error message
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('http://warals1.ddns.net:9005/verify/ ', {
        email,
        otp,
      });

      console.log('OTP Verified:', response.data);
      sessionStorage.setItem('authToken', response.data.token);
      setOpenOtpModal(false); // Close OTP modal after successful verification
      navigate('/login');
    } catch (error) {
      console.error('OTP Verification Failed:', error);
      setError('Invalid OTP. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm"> 
        <Paper elevation={3} style={{ padding: '20px', marginTop: '50px', minHeight: '60vh' }}> 
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            onSubmit={handleSubmit}
          >
            <img src="/path/to/your/logo.png" alt="Logo" style={{ marginBottom: 20 }} />
            <Typography variant="h5" component="h1" gutterBottom>
              Sign Up
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<GoogleIcon />}
              style={{ marginBottom: 10 }}
            >
              Continue with Google
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<AppleIcon />}
              style={{ marginBottom: 10 }}
            >
              Continue with Apple
            </Button>
            <Typography variant="body2" color="textSecondary" align="center" style={{ margin: '20px 0' }}>
              Or sign up with email
            </Typography>
            {error && (
              <Typography variant="body2" color="error" align="center" style={{ marginBottom: 10 }}>
                {error}
              </Typography>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="First name"
              label="First Name"
              name="first_name"
              autoComplete="given-name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Last name"
              label="Last Name"
              name="last_name"
              autoComplete="family-name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Confirm Password"
              type="password"
              id="password2"
              autoComplete="new-password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
            >
              Sign Up
            </Button>
          </Box>
        </Paper>
      </Container>

      <Modal
        aria-labelledby="otp-modal-title"
        aria-describedby="otp-modal-description"
        open={openOtpModal}
        onClose={() => setOpenOtpModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openOtpModal}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="background.paper"
            border="2px solid #000"
            boxShadow={24}
            p={4}
            style={{ margin: 'auto', maxWidth: 400, outline: 'none' }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Enter OTP
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="otp"
              label="OTP"
              name="otp"
              autoFocus
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleOtpSubmit}
              style={{ marginTop: 20 }}
            >
              Verify OTP
            </Button>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default Signup;
