import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { addStyles, EditableMathField, StaticMathField } from "react-mathquill";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import TagModal from "./components/TagModal";
import KaTeXTextField from "./KatexTextField";
import StyleIcon from "@mui/icons-material/Style";
import katex from "katex";
import "katex/dist/katex.min.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import sanitizeHtml from "sanitize-html";

// Import the Clipboard module from Quill
const Clipboard = Quill.import("modules/clipboard");

class CustomClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const html = clipboardData.getData("text/html");

    if (html) {
      const sanitizedHtml = sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img"
        ]),
        allowedAttributes: false, // Strip all attributes except essential ones
      });

      // Insert the HTML at the cursor position
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, sanitizedHtml);
      }
    } else {
      const text = clipboardData.getData("text/plain");
      // Insert plain text as HTML with line breaks
      const formattedText = `<p>${text.replace(/\n/g, "<br>")}</p>`;
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, formattedText);
      }
    }
  }
}



// Register the custom clipboard
Quill.register("modules/clipboard", CustomClipboard);


addStyles();

function FillInTheBlanks() {
  const [cards, setCards] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [questionsList, setQuestionsList] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [currentExplanation, setCurrentExplanation] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editCard, setEditCard] = useState(null);
  const [editQuestion, setEditQuestion] = useState("");
  const [editAnswers, setEditAnswers] = useState("");
  const [editExplanation, setEditExplanation] = useState("");
  const { subfolder_id } = useParams();
  const [selectedText, setSelectedText] = useState("");
  const [isExplanation, setIsExplanation] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false); // Controls dropdown visibility
  const [selectedOption, setSelectedOption] = useState(""); // State to track selected option
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagCardIndex, setTagCardIndex] = useState(null);
  const [tagModalPosition, setTagModalPosition] = useState({ top: 0, left: 0 });

  const [editorOpen, setEditorOpen] = useState(false);
  const [editorPosition, setEditorPosition] = useState({ top: 0, left: 0 });
  const [currentTextField, setCurrentTextField] = useState(null);

  // Popover Editor States
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [editingField, setEditingField] = useState(null); // 'question' or 'explanation'
  const [selectionRange, setSelectionRange] = useState(null);

  const questionRef = useRef(null);
  const explanationRef = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    if (subfolder_id) {
      fetchFlashcardsFromServer();
    }
  }, [subfolder_id]);

  const fetchFlashcardsFromServer = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.get(
        `${apiUrl}/subfolder/${subfolder_id}/fib/`
      );
      const fetchedCards = response.data.map((item) => ({
        id: item.id,
        question: item.statement,
        question_type: item.question_type,
        answer: item.answers,
        explanation: item.explanation,
        tags: item.tags,
        expanded: false,
      }));
      setCards(fetchedCards);
    } catch (error) {
      console.error("Failed to fetch flashcards from server", error);
    }
  };
  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleTextSelect = () => {
    const textArea = document.getElementById("questionTextField");
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const selected = textArea.value.substring(start, end);
    setSelectedText(selected);
  };

  // const handleQuestionChange = (e) => {
  //   setCurrentQuestion(e.target.value);
  // };

  const handleExplanationSelect = () => {
    const textArea = document.getElementById("explanationTextField");
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const selected = textArea.value.substring(start, end);
    setSelectedText(selected);
  };

  

  const toggleCardExpand = (index) => {
    const newCards = [...cards];
    newCards[index].expanded = !newCards[index].expanded;
    setCards(newCards);
  };

  const openTagModal = (index, event) => {
    const iconRect = event.target.getBoundingClientRect();
    setTagCardIndex(index);
    setTagModalPosition({
      top: iconRect.bottom + window.scrollY,
      left: iconRect.left + window.scrollX,
    });
    setTagModalOpen(true);
  };

  const openDeleteDialog = (id) => {
    setDeleteCardId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCard = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.delete(
        `${apiUrl}/subfolder/${subfolder_id}/fib/${deleteCardId}/`
      );
      const updatedCards = cards.filter((card) => card.id !== deleteCardId);
      setCards(updatedCards);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Failed to delete flashcard from server", error);
    }
  };

  const handleInputChange = (index, value) => {
    const newAnswers = [...currentAnswers];
    newAnswers[index] = value;
    setCurrentAnswers(newAnswers);
  };

  const openEditDialog = (card) => {
    setEditCard(card);
    setEditQuestion(card.question);
    setEditAnswers(card.answer);
    setEditExplanation(card.explanation);
    setEditDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTagAdded = (cardId, newTag) => {
    const updatedCards = cards.map((card) => {
      if (card.id === cardId) {
        return { ...card, tags: [...card.tags, newTag] };
      }
      return card;
    });
    setCards(updatedCards);
  };

  const handleUpdateQuestion = async () => {
    const updatedCard = {
      statement: editQuestion,
      created_by: "ScienceStudent567",
      question_type: "FIB",
      answers: editAnswers,
      explanation: editExplanation,
      tags: editCard.tags,
    };

    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.put(
        `${apiUrl}/subfolder/${subfolder_id}/fib/${editCard.id}/`,
        updatedCard
      );
      const updatedCards = cards.map((card) =>
        card.id === editCard.id
          ? {
              ...card,
              question: editQuestion,
              answer: editAnswers,
              explanation: editExplanation,
            }
          : card
      );

      setCards(updatedCards);
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Failed to update question on server", error);
    }
  };

  const handleEditChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleQuestionSubmit = () => {
    const blanksCount = (currentQuestion.match(/\[BLANK\]/g) || []).length;
    setQuestionsList([
      ...questionsList,
      {
        currentQuestion,
        answers: new Array(blanksCount).fill(""),
        submitted: false,
        explanation: "",
      },
    ]);
    setCurrentQuestionIndex(questionsList.length);
    setCurrentAnswers(new Array(blanksCount).fill(""));
  };

  const handleAnswersSubmit = async () => {
    const blanksCount = (currentQuestion.match(/\[BLANK\]/g) || []).length;
    setQuestionsList([
      ...questionsList,
      {
        currentQuestion: "",
        answers: new Array(blanksCount).fill(""),
        submitted: false,
        explanation: "",
      },
    ]);
    setCurrentQuestion(""); // Clear currentQuestion immediately
    setCurrentAnswers(new Array(blanksCount).fill(""));
    const updatedQuestionsList = [...questionsList];
    updatedQuestionsList[currentQuestionIndex].answers = currentAnswers;
    updatedQuestionsList[currentQuestionIndex].explanation = currentExplanation;
    //here we resolved the refresh part that is as soon as a set of question is submitted the field clears up

    const payload = {
      statement: currentQuestion,
      created_by: "ScienceStudent567",
      question_type: "FIB",
      answers: currentAnswers.join(","),
      explanation: currentExplanation,
      tags: [],
    };

    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.post(
        `${apiUrl}/subfolder/${subfolder_id}/fib/`,
        payload
      );
      if (response.status === 201) {
        updatedQuestionsList[currentQuestionIndex].submitted = true;
        setQuestionsList(updatedQuestionsList);
        setCurrentAnswers([]);
        setCurrentQuestionIndex(null);
        setCurrentExplanation("");
        fetchFlashcardsFromServer();
      } else {
        alert("Failed to save the question. Please try again.");
      }
    } catch (error) {
      console.error("Error saving question:", error);
      alert("Failed to save the question. Please try again.");
    }
  };

  const renderContent = (html) => {
    if (!html) return null;
  
    // Split content into LaTeX and non-LaTeX parts
    const parts = html.split(/(\$\$.+?\$\$|\$.+?\$)/g);
  
    return (
      <div>
        {parts.map((part, index) => {
          // Handle block-level LaTeX ($$...$$)
          if (part.startsWith("$$") && part.endsWith("$$")) {
            const latex = part.slice(2, -2); // Remove $$ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: true });
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content block-latex"
                />
              );
            } catch (error) {
              console.error("Block LaTeX rendering error:", error);
              return <div key={index} className="error-content">{part}</div>;
            }
          }
  
          // Handle inline LaTeX ($...$)
          if (part.startsWith("$") && part.endsWith("$")) {
            const latex = part.slice(1, -1); // Remove $ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: false });
              return (
                <span
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content inline-latex"
                />
              );
            } catch (error) {
              console.error("Inline LaTeX rendering error:", error);
              return <span key={index} className="error-content">{part}</span>;
            }
          }
  
          // Handle non-LaTeX content
          try {
            const formattedContent = part.replace(/\n/g, "<br />"); // Replace newlines
            const allowedTags = ["b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img", "h1", "h2", "h3", "h4", "h5", "h6", "ol", "ul", "li", "blockquote"];
            const allowedAttributes = {
              img: ["src", "alt"],
              a: ["href", "target"],
              span: ["style"],
              p: ["style"],
              h1: ["style"], h2: ["style"], h3: ["style"], h4: ["style"], h5: ["style"], h6: ["style"]
            };
  
            // Use sanitize-html or DOMPurify with selective sanitization
            const sanitizedContent = sanitizeHtml(formattedContent, {
              allowedTags,
              allowedAttributes,
              transformTags: {
                'p': 'span', // Convert <p> tags to <span>
                'div': 'span', // Convert <div> tags to <span>
              },
            });
  
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                className="rendered-content inline-text"
              />
            );
          } catch (error) {
            console.error("HTML rendering error:", error);
            return <span key={index} className="error-content">{part}</span>;
          }
        })}
      </div>
    );
  };
  
  
  
  
  
  
  

  const createBlanks = (question, answers, isCurrent) => {
    const parts = question.split("[BLANK]");
  
    // Create blank inputs
    const blanks = parts.slice(1).map((_, index) => (
      <input
        key={index}
        style={{
          display: 'inline-block',
          width: '100px',
          margin: '0 5px',         
          backgroundColor: '#fffbcc', 
          border: '2px solid #f0c040', 
          padding: '5px',          
          borderRadius: '4px',
          outline: 'none',
          wordBreak: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
        }}
        type="text"
        value={isCurrent ? currentAnswers[index] || "" : answers[index] || ""}
        onChange={(e) => {
          if (isCurrent) {
            const newAnswers = [...currentAnswers];
            newAnswers[index] = e.target.value;
            setCurrentAnswers(newAnswers);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleAnswersSubmit();
          }
        }}
      />
    ));
  
    // Render content with blanks
    return (
      <span style={{
        display: 'inline-flex',
        alignItems: 'baseline',
        whiteSpace: 'nowrap',
      }}>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {/* Use existing renderContent function */}
            <span style={{
              display: 'inline',
              whiteSpace: 'nowrap',
            }}>
              {renderContent(part)} {/* Reuse the existing function */}
            </span>
            {index < parts.length - 1 && blanks[index]}
          </React.Fragment>
        ))}
      </span>
    );
  };
  
  
 
  
  
  

  const handleQuestionKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleQuestionSubmit();
    }
  };

  const handleQuestionChange = (value) => {
    // Use the ReactQuill value directly
    let input = value;
    const formattedQuestion = input.replace(/_/g, "[BLANK]");
    setCurrentQuestion(formattedQuestion);
  };

  const handleIconClick = () => {
    // Toggle dropdown visibility when icon is clicked
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue === "practiceCard") {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "fib";
      navigate(
        `/practiceparticularcard/${subfolder_id}/${encodeURIComponent(
          hardCodedText
        )}` // Example: history.push('/practice-card');
      );
    }

    else if (selectedValue === 'spacedrep') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "fib";
      navigate(
        
          `/practicespacedrep/${subfolder_id}/${encodeURIComponent(
             hardCodedText
            )}`      // Example: history.push('/practice-card');
    )}

    // Hide dropdown after selection
    setDropdownVisible(false);
  };

  return (
    <div className="fill-in-the-blanks">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Enter your question with blanks as "_"
        <div style={{ position: 'relative' }}>
  <StyleIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} />

  {/* Options (only visible when dropdownVisible is true) */}
  {dropdownVisible && (
    <div
      style={{
        position: 'absolute',
        top: '30px', // Adjust position based on your layout
        right: '-30px', // Adjusted to move it further to the right
        zIndex: 1,
        backgroundColor: '#e3f2fd', // Light bluish shade
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '0', // Remove extra padding here
      }}
    >
      <div
        onClick={() => handleDropdownChange({ target: { value: 'practiceCard' } })}
        style={{
          cursor: 'pointer',
          padding: '10px 15px', // Increased padding for better coverage
          transition: 'background-color 0.3s ease',
          whiteSpace: 'nowrap', // Prevent text from wrapping
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
      >
        Practice FlashCard
      </div>
      <div
        onClick={() => handleDropdownChange({ target: { value: 'spacedrep' } })}
        style={{
          cursor: 'pointer',
          padding: '10px 15px', // Increased padding for better coverage
          transition: 'background-color 0.3s ease',
          whiteSpace: 'nowrap', // Prevent text from wrapping
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
      >
        Practice with Spaced Repetition
      </div>
    </div>
  )}
</div>{" "}
      </div>
      <div style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
        <ReactQuill
          id="questionTextField"
          variant="outlined"
          value={currentQuestion}
          // onChange={(e) => setCurrentQuestion(e.target.value)}
          onChange={handleQuestionChange}
          onKeyDown={handleQuestionKeyDown}
          // onMouseUp={handleTextSelect}
          placeholder="Enter your question here"
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction
              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],
              ["clean"], // remove formatting button
            ],
          }}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "code-block",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
            "color",
            "background",
            "align",
            "script",
            { name: 'preserve-spaces', type: 'inline' },
          ]}
          InputProps={{
            style: {
              wordBreak: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
            },
          }}
          style={{
            wordBreak: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        />

        <div
          className="mt-2"
          style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
        >
          {renderContent(currentQuestion)}
        </div>
      </div>
      {questionsList.map(
        (q, index) =>
          !q.submitted && (
            <div
              key={index}
              className="question-container"
              style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
            >
              <p
                style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
              >
                {createBlanks(
                  q.currentQuestion,
                  q.answers,
                  index === currentQuestionIndex
                )}
              </p>

              {index === currentQuestionIndex && (
                <div>
                  Enter an optional explanation
                  <ReactQuill
                    id="explanationTextField"
                    variant="outlined"
                    value={currentExplanation}
                    onChange={setCurrentExplanation}
                    onMouseUp={handleExplanationSelect}
                    placeholder="Enter your explanation here"
                    // onKeyDown={handleQuestionKeyDown}
                    // onKeyDown={(e) => {
                    //   if (e.key === 'Enter') {
                    //     e.preventDefault();
                    //     handleAnswersSubmit();
                    //   }
                    // }}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"], // toggled buttons
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }], // custom button values
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }], // superscript/subscript
                        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                        [{ direction: "rtl" }], // text direction
                        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                        [{ font: [] }],
                        [{ align: [] }],
                        ["clean"], // remove formatting button
                      ],
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "code-block",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "image",
                      "video",
                      "color",
                      "background",
                      "align",
                      "script",
                       { name: 'preserve-spaces', type: 'inline' },
                    ]}
                    InputProps={{
                      style: {
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      },
                    }}
                  />
                  <div
                    className="mt-2"
                    style={{
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {renderContent(currentExplanation)}
                  </div>
                </div>
              )}
            </div>
          )
      )}
      <Button
        onClick={handleAnswersSubmit}
        variant="contained"
        sx={{
          backgroundColor: "#1565c0",
          color: "white",
          "&:hover": { backgroundColor: "#0d47a1" },
        }}
      >
        Submit{" "}
      </Button>
      <div>
  <ul className="card-list">
    {cards.map((card, index) => (
      <li
        key={index}
        className="card"
        style={{
          background: 'linear-gradient(to right, #dfe6f9, rgb(124 182 246))',
          position: 'relative',
          padding: '16px',
          borderRadius: '8px',
          marginBottom: '12px',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          maxWidth: 'calc(100% - 80px)', // Add maxWidth for controlled width
          whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
        }}
      >
        {/* Question */}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            paddingRight: '40px', // Adjust padding if needed
            maxWidth: 'calc(100% - 70px)',
            whiteSpace: 'pre-wrap'
          }}
        >
          <strong style={{ marginRight: '2px' }}>Q:</strong>
          <span style={{ flex: 1 }}><strong>{renderContent(card.question)}</strong></span>
        </div>

        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <i
            className={`bi ${card.expanded ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand'}`}
            title={card.expanded ? 'Contract' : 'Expand'}
            onClick={() => toggleCardExpand(index)}
            style={{ cursor: 'pointer' }}
          ></i>
          <i className="bi bi-pencil" title="Edit" onClick={() => openEditDialog(card)} style={{ cursor: 'pointer' }}></i>
          <i className="bi bi-trash" title="Delete" onClick={() => openDeleteDialog(card.id)} style={{ cursor: 'pointer' }}></i>
          <i className="bi bi-tag" title="Tag" onClick={(e) => openTagModal(index, e)} style={{ cursor: 'pointer' }}></i>
        </div>

        {/* Expanded Content */}
        {card.expanded && (
          <>
            {/* Answer */}
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
              }}
            >
              <strong style={{ marginRight: '5px' }}>A:</strong>
              <span style={{ flex: 1 }}>{renderContent(card.answer)}</span>
            </div>

            {/* Explanation */}
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                maxWidth: 'calc(100% - 100px)', // Preserve whitespace and allow wrapping
              }}
            >
              <strong style={{ marginRight: '5px' }}>Explanation:</strong>
              <span style={{ flex: 1 }}>{renderContent(card.explanation)}</span>
            </div>

            {/* Tags */}
            <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            <p>
                      Tags:
                      {card.tags.map((tag, idx) => (
                        <span key={tag.id}>
                          [{tag.name}{idx < card.tags.length - 1 ? ', ' : ''}]
                        </span>
                      ))}
                    </p>
            </div>
          </>
        )}
      </li>
    ))}
  </ul>
</div>






      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this flashcard?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteCard}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Flashcard</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: "16px" }}>
            <label>Question</label>
            <ReactQuill
              value={editQuestion}
              onChange={setEditQuestion}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction
                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],
                  ["clean"], // remove formatting button
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "code-block",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
                "color",
                "background",
                "align",
                "script",
                 { name: 'preserve-spaces', type: 'inline' },
              ]}
            />
          </div>

          <div style={{ marginBottom: "16px" }}>
            <label>Answer</label>
            <TextField
              value={editAnswers}
              onChange={(e) => setEditAnswers(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ marginBottom: "16px" }}>
            <label>Explanation</label>
            <ReactQuill
              value={editExplanation}
              onChange={setEditExplanation}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction
                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],
                  ["clean"], // remove formatting button
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "code-block",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
                "color",
                "background",
                "align",
                "script",
                 { name: 'preserve-spaces', type: 'inline' },
              ]}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleUpdateQuestion}>Save</Button>
        </DialogActions>
      </Dialog>
      <TagModal
        open={tagModalOpen}
        position={tagModalPosition}
        onClose={() => setTagModalOpen(false)}
        subfolderId={subfolder_id}
        cardId={cards[tagCardIndex]?.id}
        cardQuestionType={cards[tagCardIndex]?.question_type}
        onTagAdded={handleTagAdded}
      />
    </div>
  );
}

export default FillInTheBlanks;

// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { useNavigate, useParams } from 'react-router-dom';
// import { addStyles, StaticMathField } from 'react-mathquill';
// import { TextField, Button } from '@mui/material';
// import StyleIcon from '@mui/icons-material/Style';
// import katex from 'katex';
// import 'katex/dist/katex.min.css';

// addStyles();

// function FillInTheBlanks() {
//   const [cards, setCards] = useState([]);
//   const [currentQuestion, setCurrentQuestion] = useState('');
//   const [questionsList, setQuestionsList] = useState([]);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
//   const [currentAnswers, setCurrentAnswers] = useState([]);
//   const [currentExplanation, setCurrentExplanation] = useState('');
//   const { subfolder_id } = useParams();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (subfolder_id) {
//       fetchFlashcardsFromServer();
//     }
//   }, [subfolder_id]);

//   const fetchFlashcardsFromServer = async () => {
//     try {
//       const apiUrl = process.env.REACT_APP_BASE_URL;
//       const response = await axios.get(`${apiUrl}/subfolder/${subfolder_id}/fib/`);
//       const fetchedCards = response.data.map(item => ({
//         id: item.id,
//         question: item.statement,
//         question_type: item.question_type,
//         answer: item.answers,
//         explanation: item.explanation,
//         tags: item.tags,
//         expanded: false,
//       }));
//       setCards(fetchedCards);
//     } catch (error) {
//       console.error('Failed to fetch flashcards from server', error);
//     }
//   };

//   const handleQuestionChange = (e) => {
//     setCurrentQuestion(e.target.value);
//   };

//   const handleInputChange = (index, value) => {
//     const newAnswers = [...currentAnswers];
//     newAnswers[index] = value;
//     setCurrentAnswers(newAnswers);
//   };

//   const handleQuestionSubmit = () => {
//     const blanksCount = (currentQuestion.match(/_/g) || []).length;
//     setQuestionsList([...questionsList, { currentQuestion, answers: new Array(blanksCount).fill(''), submitted: false, explanation: '' }]);
//     setCurrentQuestionIndex(questionsList.length);
//     setCurrentAnswers(new Array(blanksCount).fill(''));
//     setCurrentQuestion('');
//   };

//   const handleAnswersSubmit = async () => {
//     const updatedQuestionsList = [...questionsList];
//     updatedQuestionsList[currentQuestionIndex].answers = currentAnswers;
//     updatedQuestionsList[currentQuestionIndex].explanation = currentExplanation;

//     const payload = {
//       statement: updatedQuestionsList[currentQuestionIndex].question,
//       created_by: 'ScienceStudent567',
//       question_type: 'FIB',
//       answers: currentAnswers.join(','),
//       currentExplanation,
//       tags: []
//     };

//     try {
//       const apiUrl = process.env.REACT_APP_BASE_URL;
//       const response = await axios.post(`${apiUrl}/subfolder/${subfolder_id}/fib/`, payload);
//       if (response.status === 201) {
//         updatedQuestionsList[currentQuestionIndex].submitted = true;
//         setQuestionsList(updatedQuestionsList);
//         setCurrentAnswers([]);
//         setCurrentQuestionIndex(null);
//         setCurrentExplanation('');
//         fetchFlashcardsFromServer();
//       } else {
//         alert('Failed to save the question. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error saving question:', error);
//       alert('Failed to save the question. Please try again.');
//     }
//   };

//   const createBlanks = (question, answers, isCurrent) => {
//     const parts = question.split('_'); // Split at underscores
//     return parts.map((part, index) => (
//       <span key={index} className="question-part">
//         <StaticMathField>{part}</StaticMathField>
//         {/* Render blank input after each static part except last */}
//         {index < parts.length - 1 && (
//           <input
//             key={index}
//             className="blank-input"
//             type="text"
//             value={isCurrent ? currentAnswers[index] || '' : answers[index] || ''}
//             onChange={(e) => handleInputChange(index, e.target.value)}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 e.preventDefault();
//                 handleAnswersSubmit();
//               }
//             }}
//           />
//         )}
//       </span>
//     ));
//   };

//   const handleQuestionKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       e.preventDefault();
//       handleQuestionSubmit();
//     }
//   };

//   return (
//     <div className="fill-in-the-blanks">
//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         Enter your question with blanks as "_"
//         <StyleIcon onClick={() => navigate(`/practiceparticularcard/${subfolder_id}/fib`)} style={{ cursor: 'pointer' }} />
//       </div>
//       <TextField
//         variant="outlined"
//         value={currentQuestion}
//         onChange={handleQuestionChange}
//         onKeyDown={handleQuestionKeyDown}
//         placeholder="Enter your question here"
//         fullWidth
//         multiline
//       />
//       {questionsList.map((q, index) => (
//         !q.submitted && (
//           <div key={index} className="question-container">
//             <p>{createBlanks(q.currentQuestion, q.answers, index === currentQuestionIndex)}</p>
//             {index === currentQuestionIndex && (
//               <div>
//                 <TextField
//                   variant="outlined"
//                   value={currentExplanation}
//                   onChange={(e) => setCurrentExplanation(e.target.value)}
//                   placeholder="Enter your explanation here"
//                   fullWidth
//                   multiline
//                 />
//                 <Button onClick={handleAnswersSubmit}>Submit Answer</Button>
//               </div>
//             )}
//           </div>
//         )
//       ))}
//     </div>
//   );
// }

// export default FillInTheBlanks;
