import React, { useState } from 'react';

const ProfilePage = () => {
    const [profileImage, setProfileImage] = useState(null);
    const [username, setUsername] = useState('shweta4866470');
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [school, setSchool] = useState('');
    const [socialLink, setSocialLink] = useState('');

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCancel = () => {
        setUsername('');
        setName('');
        setBio('');
        setSchool('');
        setSocialLink('');
        setProfileImage(null);
    };

    const handleSave = () => {
        console.log('Profile updated:', { profileImage, username, name, bio, school, socialLink });
    };

    return (
        <div style={styles.container}>
            <div style={styles.formContainer}>
                <h2 style={styles.header}>Edit Profile</h2>
                {/* Profile Image Upload */}
                <div style={styles.profileImageContainer}>
                    <img 
                        src={profileImage || "https://via.placeholder.com/150"} 
                        alt="Profile" 
                        style={styles.profileImage} 
                    />
                    <label htmlFor="imageUpload" style={styles.editIcon}>
                        <span role="img" aria-label="edit">✏️</span>
                    </label>
                    <input 
                        id="imageUpload" 
                        type="file" 
                        accept="image/*" 
                        onChange={handleImageChange} 
                        style={styles.fileInput}
                    />
                </div>

                {/* Username */}
                <div style={styles.inputGroup}>
                    <label>Username</label>
                    <input 
                        type="text" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                        style={styles.input}
                    />
                </div>

                {/* Name */}
                <div style={styles.inputGroup}>
                    <label>Name</label>
                    <input 
                        type="text" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        style={styles.input}
                    />
                </div>

                {/* Bio */}
                <div style={styles.inputGroup}>
                    <label>Bio</label>
                    <textarea 
                        value={bio} 
                        onChange={(e) => setBio(e.target.value)} 
                        placeholder="Add a bio to your profile"
                        style={styles.textarea}
                    />
                </div>

                {/* School */}
                <div style={styles.inputGroup}>
                    <label>School</label>
                    <input 
                        type="text" 
                        value={school} 
                        onChange={(e) => setSchool(e.target.value)} 
                        placeholder="Add your school"
                        style={styles.input}
                    />
                </div>

                {/* Social Link */}
                <div style={styles.inputGroup}>
                    <label>Social Link</label>
                    <input 
                        type="text" 
                        value={socialLink} 
                        onChange={(e) => setSocialLink(e.target.value)} 
                        placeholder="Add your social link"
                        style={styles.input}
                    />
                </div>

                {/* Buttons */}
                <div style={styles.buttonGroup}>
                    <button style={styles.cancelButton} onClick={handleCancel}>Cancel</button>
                    <button style={styles.saveButton} onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    );
};

// Basic styles for simplicity
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
    },
    formContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        width: '100%',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    profileImageContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    profileImage: {
        borderRadius: '50%',
        width: '100px',
        height: '100px',
    },
    editIcon: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: '5px',
        cursor: 'pointer',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    fileInput: {
        display: 'none',
    },
    inputGroup: {
        marginBottom: '15px',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        resize: 'vertical',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    cancelButton: {
        backgroundColor: '#ccc',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    saveButton: {
        backgroundColor: '#142be1',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default ProfilePage;
