// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { Dialog, DialogActions, DialogContent,  Select,
//   MenuItem,
//   InputLabel,
//   FormControl,DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
// import TagModal from './TagModal';
// import MathJaxRenderer from './MathJaxRenderer';
// import katex from 'katex';
// import 'katex/dist/katex.min.css';


// const TrueFalse = () => {
//   const [cards, setCards] = useState([]);
//   const [currentQuestion, setCurrentQuestion] = useState('');
//   const [currentAnswer, setCurrentAnswer] = useState('');
//   const [currentExplanation, setCurrentExplanation] = useState('');
//   const [isExplanation, setIsExplanation] = useState(false);
//   const { subfolder_id } = useParams();
//   const [selectedText, setSelectedText] = useState('');
//   // Edit Modal States
//   const [editModalOpen, setEditModalOpen] = useState(false);
//   const [editCardIndex, setEditCardIndex] = useState(null);
//   const [editQuestion, setEditQuestion] = useState('');
//   const [editAnswer, setEditAnswer] = useState('');
//   const [editExplanation, setEditExplanation] = useState('');

//   // Delete Dialog States
//   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
//   const [deleteCardIndex, setDeleteCardIndex] = useState(null);

//   // Tag Modal States
//   const [tagModalOpen, setTagModalOpen] = useState(false);
//   const [tagCardIndex, setTagCardIndex] = useState(null);
//   const [tagModalPosition, setTagModalPosition] = useState({ top: 0, left: 0 });

//   useEffect(() => {
//     if (subfolder_id) {
//       fetchFlashcardsFromServer();
//     }
//   }, [subfolder_id]);

//   const fetchFlashcardsFromServer = async () => {
//     try {
//       const response = await axios.get(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/`);
//       const fetchedCards = response.data.map(item => ({
//         id: item.id,
//         question: item.statement,
//         question_type: item.question_type,
//         answer: item.answers,
//         explanation: item.explanation,
//         expanded: false,
//         tags: item.tags // Store the entire tags array
//       }));
//       setCards(fetchedCards);
//     } catch (error) {
//       console.error('Failed to fetch flashcards from server', error);
//     }
//   };

//   const handleConvertToKaTeX = () => {
//     if (selectedText) {
//       const textArea = document.getElementById('questionTextField');
//       const textBefore = textArea.value.substring(0, textArea.selectionStart);
//       const textAfter = textArea.value.substring(textArea.selectionEnd);
//       const newText = `${textBefore}$$${selectedText}$$${textAfter}`;
//       setCurrentQuestion(newText);
//       setSelectedText('');
//     }
//   };

//   const handleConvertExplanationToKaTeX = () => {
//     if (selectedText) {
//       const textArea = document.getElementById('explanationTextField');
//       const textBefore = textArea.value.substring(0, textArea.selectionStart);
//       const textAfter = textArea.value.substring(textArea.selectionEnd);
//       const newText = `${textBefore}$$${selectedText}$$${textAfter}`;
//       setCurrentExplanation(newText);
//       setSelectedText('');
//     }
//   };

//   const handleTextSelect = () => {
//     const textArea = document.getElementById('questionTextField');
//     const start = textArea.selectionStart;
//     const end = textArea.selectionEnd;
//     const selected = textArea.value.substring(start, end);
//     setSelectedText(selected);
//   };

//   const handleExplanationSelect = () => {
//     const textArea = document.getElementById('explanationTextField');
//     const start = textArea.selectionStart;
//     const end = textArea.selectionEnd;
//     const selected = textArea.value.substring(start, end);
//     setSelectedText(selected);
//   };

//   const renderContent = (text) => {
//     const parts = text.split(/(\$\$.+?\$\$|\$.+?\$)/g); // Split by LaTeX syntax
  
//     return parts.map((part, index) => {
//       if (part.startsWith('$$') && part.endsWith('$$')) {
//         // Block-level KaTeX
//         const latex = part.slice(2, -2); // Remove the $$ delimiters
//         try {
//           const renderedLatex = katex.renderToString(latex, { displayMode: true });
//           return <div key={index} dangerouslySetInnerHTML={{ __html: renderedLatex }} />;
//         } catch (err) {
//           console.error('KaTeX rendering failed:', err);
//           return <span key={index}>{part}</span>;
//         }
//       } else if (part.startsWith('$') && part.endsWith('$')) {
//         // Inline KaTeX
//         const latex = part.slice(1, -1); // Remove the $ delimiters
//         try {
//           const renderedLatex = katex.renderToString(latex);
//           return <span key={index} dangerouslySetInnerHTML={{ __html: renderedLatex }} />;
//         } catch (err) {
//           console.error('KaTeX rendering failed:', err);
//           return <span key={index}>{part}</span>;
//         }
//       }
//       return <span key={index}>{part}</span>;
//     });
//   };

//   const handleApiCall = async () => {
//     const payload = {
//       statement: currentQuestion,
//       created_by: 'Admin',
//       question_type: 'TRUEFALSE',
//       answers: currentAnswer === 'True',
//       explanation: currentExplanation,
//       tags: []
//     };

//     try {
//       const response = await fetch(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(payload)
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const data = await response.json();
//       console.log('Success:', data);
//       fetchFlashcardsFromServer();
//       setCurrentQuestion('');
//       setCurrentAnswer('');
//       setCurrentExplanation('');
//       setIsExplanation(false);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter' && !isExplanation) {
//       setIsExplanation(true);
//     } else if (e.key === 'Enter' && isExplanation) {
//       handleApiCall();
//     }
//   };

//   const toggleCardExpand = (index) => {
//     const newCards = [...cards];
//     newCards[index].expanded = !newCards[index].expanded;
//     setCards(newCards);
//   };

//   // Edit Modal Functions
//   const openEditModal = (index) => {
//     setEditCardIndex(index);
//     setEditQuestion(cards[index].question);
//     setEditAnswer(cards[index].answer ? 'True' : 'False');
//     setEditExplanation(cards[index].explanation);
//     setEditModalOpen(true);
//   };

//   const handleEditChange = (setter) => (event) => {
//     setter(event.target.value);
//   };

//   const handleUpdateCard = async () => {
//     const updatedCard = {
//       statement: editQuestion,
//       created_by: 'Admin',
//       question_type: 'TRUEFALSE',
//       answers: editAnswer === 'True',
//       explanation: editExplanation,
//       tags: cards[editCardIndex].tags
//     };

//     try {
//       const cardId = cards[editCardIndex].id;
//       await axios.put(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/${cardId}/`, updatedCard);

//       const updatedCards = [...cards];
//       updatedCards[editCardIndex] = {
//         ...updatedCards[editCardIndex],
//         question: editQuestion,
//         answer: editAnswer === 'True',
//         explanation: editExplanation,
//       };
//       setCards(updatedCards);
//       setEditModalOpen(false);
//     } catch (error) {
//       console.error('Failed to update flashcard on server', error);
//     }
//   };

//   const handleCloseModal = () => {
//     setEditModalOpen(false);
//   };

//   // Delete Dialog Functions
//   const openDeleteDialog = (index) => {
//     setDeleteCardIndex(index);
//     setDeleteDialogOpen(true);
//   };

//   const handleDeleteCard = async () => {
//     try {
//       const cardId = cards[deleteCardIndex].id;
//       await axios.delete(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/${cardId}/`);
//       const updatedCards = cards.filter((_, index) => index !== deleteCardIndex);
//       setCards(updatedCards);
//       setDeleteDialogOpen(false);
//     } catch (error) {
//       console.error('Failed to delete flashcard on server', error);
//     }
//   };

//   const handleCloseDeleteDialog = () => {
//     setDeleteDialogOpen(false);
//   };

//   // Tag Modal Functions
//   const openTagModal = (index, event) => {
//     const iconRect = event.target.getBoundingClientRect();
//     setTagCardIndex(index);
//     setTagModalPosition({
//       top: iconRect.bottom + window.scrollY,
//       left: iconRect.left + window.scrollX,
//     });
//     setTagModalOpen(true);
//   };

//   const handleTagAdded = (cardId, newTag) => {
//     const updatedCards = cards.map(card => {
//       if (card.id === cardId) {
//         return { ...card, tags: [...card.tags, newTag] };
//       }
//       return card;
//     });
//     setCards(updatedCards);
//   };

//   return (
//     <div>
//       <h2>True/False Flashcards</h2>
//       <div className="mb-4">
//         <label className="block text-gray-700 font-semibold">Question:</label>
//         <TextField
//           id="questionTextField"
//           variant="outlined"
//           value={currentQuestion}
//           onChange={(e) => setCurrentQuestion(e.target.value)}
//           onMouseUp={handleTextSelect}
//            placeholder="Enter your question here"
//           fullWidth
//           multiline
//         />
//         {/* <Button onClick={handleConvertToKaTeX} color="secondary" variant="outlined">
//         ∑
//         </Button> */}
//         <div className="mt-2">
//           {renderContent(currentQuestion)}
//         </div>
//         <label className="block text-gray-700 font-semibold">Answer:</label>
//         <select
//           value={currentAnswer}
//           onChange={(e) => setCurrentAnswer(e.target.value)}
//           className="mt-1 p-2 w-full border border-gray-300 rounded-md"
//           onKeyDown={handleKeyDown}
//         >
//           <option value="">Select</option>
//           <option value="True">True</option>
//           <option value="False">False</option>
//         </select>
//       </div>


//       <div className="mb-4">
//         <label className="block text-gray-700 font-semibold">Explanation:</label>
//         <TextField
//           id="explanationTextField"
//           variant="outlined"
//           value={currentExplanation}
//           onChange={(e) => setCurrentExplanation(e.target.value)}
//           onMouseUp={handleExplanationSelect}
//           placeholder="Enter your explanation here"
//           onKeyDown={handleKeyDown}
//           fullWidth
//           multiline
//         />
//         {/* <Button onClick={handleConvertExplanationToKaTeX} color="secondary" variant="outlined">
//         ∑
//         </Button> */}
//         <div className="mt-2">
//           {renderContent(currentExplanation)}
//         </div>
       
//       </div>
//       <div>
//       <ul className="card-list">
//   {cards.map((card, index) => (
//     <li key={index} className="card" style={{ background: 'linear-gradient(to right, #dfe6f9, rgb(124 182 246))', position: 'relative' }}>
//       <div style={{ display: 'flex', alignItems: 'center' }}>
//         <strong>Q: {renderContent(card.question)}</strong> {/* Rendered with KaTeX */}
//       </div>
//       <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '10px' }}>
//         <i className={`bi ${card.expanded ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand'}`} title={card.expanded ? 'Contract' : 'Expand'} onClick={() => toggleCardExpand(index)}></i>
//         <i className="bi bi-pencil" title="Edit" onClick={() => openEditModal(index)}></i>
//         <i className="bi bi-trash" title="Delete" onClick={() => openDeleteDialog(index)}></i>
//         <i className="bi bi-tag" title="Tag" onClick={(e) => openTagModal(index, e)}></i>
//       </div>
//       {card.expanded && (
//         <>
//           <p>A: {card.answer ? 'True' : 'False'}</p>
//           {card.explanation && <p>Explanation: {renderContent(card.explanation)}</p>} {/* Rendered with KaTeX */}
//           <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
//             <p>
//               Tags:
//               {card.tags.map((tag, index) => (
//                 <span key={tag.id}>
//                   [{tag.name}{index < card.tags.length - 1 ? ', ' : ''}]
//                 </span>
//               ))}
//             </p>
//           </div>
//         </>
//       )}
//     </li>
//   ))}
// </ul>
// </div>
// <Dialog open={editModalOpen} onClose={handleCloseModal}>
//         <DialogTitle>Edit Flashcard</DialogTitle>
//         <DialogContent>
//           <DialogContentText>Edit your flashcard details below.</DialogContentText>
//           <TextField
//             label="Question"
//             variant="outlined"
//             value={editQuestion}
//             onChange={(e) => setEditQuestion(e.target.value)}
//             fullWidth
//             multiline
//             style={{ marginTop: '16px', marginBottom: '16px' }}
//           />

//           {/* Edit Answer Field */}
//           <FormControl variant="outlined" fullWidth style={{ marginBottom: '16px' }}>
//             <InputLabel id="edit-answer-label">Answer</InputLabel>
//             <Select
//               labelId="edit-answer-label"
//               value={editAnswer}
//               onChange={(e) => setEditAnswer(e.target.value)}
//               label="Answer"
//             >
//               <MenuItem value="True">True</MenuItem>
//               <MenuItem value="False">False</MenuItem>
//             </Select>
//           </FormControl>

//           {/* Edit Explanation Field */}
//           <TextField
//             label="Explanation"
//             variant="outlined"
//             value={editExplanation}
//             onChange={(e) => setEditExplanation(e.target.value)}
//             fullWidth
//             multiline
//             style={{ marginBottom: '16px' }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseModal} color="secondary" variant="outlined">
//             Cancel
//           </Button>
//           <Button onClick={handleUpdateCard} color="primary" variant="contained">
//             Update
//           </Button>
//         </DialogActions>
//       </Dialog>


//       <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
//         <DialogTitle>Delete Flashcard</DialogTitle>
//         <DialogContent>
//           <DialogContentText>Are you sure you want to delete this flashcard?</DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
//           <Button onClick={handleDeleteCard}>Delete</Button>
//         </DialogActions>
//       </Dialog>

//       <TagModal
//         open={tagModalOpen}
//         position={tagModalPosition}
//         onClose={() => setTagModalOpen(false)}
//         subfolderId={subfolder_id}
//         cardId={cards[tagCardIndex]?.id}
//         cardQuestionType={cards[tagCardIndex]?.question_type}
//         onTagAdded={handleTagAdded}
//       />
//     </div>
//   );
// };

// export default TrueFalse;


import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { 
  Dialog, DialogActions, DialogContent, 
  Select, MenuItem, InputLabel, FormControl, 
  DialogContentText, DialogTitle, Button, Popover 
} from '@mui/material';
import TagModal from './TagModal';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StyleIcon from "@mui/icons-material/Style";
import { useNavigate } from "react-router-dom";
import sanitizeHtml from 'sanitize-html';

// Import the Clipboard module from Quill
const Clipboard = Quill.import("modules/clipboard");

class CustomClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const html = clipboardData.getData("text/html");

    if (html) {
      const sanitizedHtml = sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img"
        ]),
        allowedAttributes: false, // Strip all attributes except essential ones
      });

      // Insert the HTML at the cursor position
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, sanitizedHtml);
      }
    } else {
      const text = clipboardData.getData("text/plain");
      // Insert plain text as HTML with line breaks
      const formattedText = `<p>${text.replace(/\n/g, "<br>")}</p>`;
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, formattedText);
      }
    }
  }
}



// Register the custom clipboard
Quill.register("modules/clipboard", CustomClipboard);


const TrueFalse = () => {
  const [cards, setCards] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [currentExplanation, setCurrentExplanation] = useState('');
  const [isExplanation, setIsExplanation] = useState(false);
  const { subfolder_id } = useParams();

  // Edit Modal States
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCardIndex, setEditCardIndex] = useState(null);
  const [editQuestion, setEditQuestion] = useState('');
  const [editAnswer, setEditAnswer] = useState('');
  const [editExplanation, setEditExplanation] = useState('');

  // Delete Dialog States
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCardIndex, setDeleteCardIndex] = useState(null);

  // Tag Modal States
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagCardIndex, setTagCardIndex] = useState(null);
  const [tagModalPosition, setTagModalPosition] = useState({ top: 0, left: 0 });

  // Popover Editor States
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const [editingField, setEditingField] = useState(null); // 'question' or 'explanation'
  const [selectionRange, setSelectionRange] = useState(null);

  // Refs to ReactQuill editors
  const questionQuillRef = useRef(null);
  const explanationQuillRef = useRef(null);
  
  const [dropdownVisible, setDropdownVisible] = useState(false); // Controls dropdown visibility
  const [selectedOption, setSelectedOption] = useState(''); // State to track selected option
  const navigate = useNavigate();

  useEffect(() => {
    if (subfolder_id) {
      fetchFlashcardsFromServer();
    }
  }, [subfolder_id]);

  const fetchFlashcardsFromServer = async () => {
    try {
      const response = await axios.get(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/`);
      const fetchedCards = response.data.map(item => ({
        id: item.id,
        question: item.statement, // Assuming HTML content
        question_type: item.question_type,
        answer: item.answers,
        explanation: item.explanation, // Assuming HTML content
        expanded: false,
        tags: item.tags // Store the entire tags array
      }));
      setCards(fetchedCards);
    } catch (error) {
      console.error('Failed to fetch flashcards from server', error);
    }
  };

  const renderContent = (html) => {
    if (!html) return null;
  
    // Split content into LaTeX and non-LaTeX parts
    const parts = html.split(/(\$\$.+?\$\$|\$.+?\$)/g);
  
    return (
      <div>
        {parts.map((part, index) => {
          // Handle block-level LaTeX ($$...$$)
          if (part.startsWith("$$") && part.endsWith("$$")) {
            const latex = part.slice(2, -2); // Remove $$ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: true });
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content block-latex"
                />
              );
            } catch (error) {
              console.error("Block LaTeX rendering error:", error);
              return <div key={index} className="error-content">{part}</div>;
            }
          }
  
          // Handle inline LaTeX ($...$)
          if (part.startsWith("$") && part.endsWith("$")) {
            const latex = part.slice(1, -1); // Remove $ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: false });
              return (
                <span
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content inline-latex"
                />
              );
            } catch (error) {
              console.error("Inline LaTeX rendering error:", error);
              return <span key={index} className="error-content">{part}</span>;
            }
          }
  
          // Handle non-LaTeX content
          try {
            const formattedContent = part.replace(/\n/g, "<br />"); // Replace newlines
            const allowedTags = ["b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img"];
            const allowedAttributes = {
              img: ["src", "alt"],
              a: ["href", "target"],
              span: ["style"],
              p: ["style"],
            };
  
            // Use sanitize-html or DOMPurify with selective sanitization
            const sanitizedContent = sanitizeHtml(formattedContent, {
              allowedTags,
              allowedAttributes,
              transformTags: {
                'p': 'span', // Convert <p> tags to <span>
                'div': 'span', // Convert <div> tags to <span>
              },
            });
  
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                className="rendered-content inline-text"
              />
            );
          } catch (error) {
            console.error("HTML rendering error:", error);
            return <span key={index} className="error-content">{part}</span>;
          }
        })}
      </div>
    );
  };
  
  

  const handleApiCall = async () => {
    const payload = {
      statement: currentQuestion, // Assuming HTML content
      created_by: 'Admin',
      question_type: 'TRUEFALSE',
      answers: currentAnswer === 'True',
      explanation: currentExplanation, // Assuming HTML content
      tags: []
    };

    try {
      const response = await fetch(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data);
      fetchFlashcardsFromServer();
      setCurrentQuestion('');
      setCurrentAnswer('');
      setCurrentExplanation('');
      setIsExplanation(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isExplanation) {
      setIsExplanation(true);
    } else if (e.key === 'Enter' && isExplanation) {
      handleApiCall();
    }
  };

  const toggleCardExpand = (index) => {
    const newCards = [...cards];
    newCards[index].expanded = !newCards[index].expanded;
    setCards(newCards);
  };

  // Edit Modal Functions
  const openEditModal = (index) => {
    setEditCardIndex(index);
    setEditQuestion(cards[index].question);
    setEditAnswer(cards[index].answer ? 'True' : 'False');
    setEditExplanation(cards[index].explanation);
    setEditModalOpen(true);
  };

  const handleEditChange = (setter) => (value) => {
    setter(value);
  };

  const handleUpdateCard = async () => {
    const updatedCard = {
      statement: editQuestion, // Assuming HTML content
      created_by: 'Admin',
      question_type: 'TRUEFALSE',
      answers: editAnswer === 'True',
      explanation: editExplanation, // Assuming HTML content
      tags: cards[editCardIndex].tags
    };

    try {
      const cardId = cards[editCardIndex].id;
      await axios.put(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/${cardId}/`, updatedCard);

      const updatedCards = [...cards];
      updatedCards[editCardIndex] = {
        ...updatedCards[editCardIndex],
        question: editQuestion,
        answer: editAnswer === 'True',
        explanation: editExplanation,
      };
      setCards(updatedCards);
      setEditModalOpen(false);
    } catch (error) {
      console.error('Failed to update flashcard on server', error);
    }
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  // Delete Dialog Functions
  const openDeleteDialog = (index) => {
    setDeleteCardIndex(index);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCard = async () => {
    try {
      const cardId = cards[deleteCardIndex].id;
      await axios.delete(`http://warals1.ddns.net:9005/subfolder/${subfolder_id}/truefalse/${cardId}/`);
      const updatedCards = cards.filter((_, index) => index !== deleteCardIndex);
      setCards(updatedCards);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Failed to delete flashcard on server', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Tag Modal Functions
  const openTagModal = (index, event) => {
    const iconRect = event.target.getBoundingClientRect();
    setTagCardIndex(index);
    setTagModalPosition({
      top: iconRect.bottom + window.scrollY,
      left: iconRect.left + window.scrollX,
    });
    setTagModalOpen(true);
  };

  const handleTagAdded = (cardId, newTag) => {
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        return { ...card, tags: [...card.tags, newTag] };
      }
      return card;
    });
    setCards(updatedCards);
  };

  // Popover Editor Functions
  const handleContextMenu = (event, field) => {
    event.preventDefault();
    const quillRef = field === 'question' ? questionQuillRef.current : explanationQuillRef.current;
    if (quillRef) {
      const quill = quillRef.getEditor();
      const selection = quill.getSelection();
      if (selection && selection.length > 0) {
        const selectedText = quill.getText(selection.index, selection.length);
        setPopoverContent(selectedText);
        setEditingField(field);
        setPopoverAnchorEl(event.currentTarget);
        setPopoverOpen(true);
        setSelectionRange(selection);
      }
    }
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setPopoverAnchorEl(null);
    setPopoverContent('');
    setEditingField(null);
    setSelectionRange(null);
  };

  const handlePopoverSave = () => {
    if (selectionRange && editingField) {
      const quillRef = editingField === 'question' ? questionQuillRef.current : explanationQuillRef.current;
      if (quillRef) {
        const quill = quillRef.getEditor();
        quill.deleteText(selectionRange.index, selectionRange.length);
        quill.insertText(selectionRange.index, popoverContent);
        // Update the corresponding state
        if (editingField === 'question') {
          setCurrentQuestion(quill.root.innerHTML);
        } else {
          setCurrentExplanation(quill.root.innerHTML);
        }
      }
    }
    handlePopoverClose();
  };

  const handlePopoverChange = (value) => {
    setPopoverContent(value);
  };
  const handleIconClick = () => {
    // Toggle dropdown visibility when icon is clicked
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    
    if (selectedValue === 'practiceCard') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "truefalse";
      navigate(
        
          `/practiceparticularcard/${subfolder_id}/${encodeURIComponent(
             hardCodedText
            )}`      // Example: history.push('/practice-card');
    )}

    else if (selectedValue === 'spacedrep') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "truefalse";
      navigate(
        
          `/practicespacedrep/${subfolder_id}/${encodeURIComponent(
             hardCodedText
            )}`      // Example: history.push('/practice-card');
    )}

    // Hide dropdown after selection
    setDropdownVisible(false);
  };

  return (
    <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <h2>True/False Flashcards</h2>
      <div style={{ position: 'relative' }}>
  <StyleIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} />

  {/* Options (only visible when dropdownVisible is true) */}
  {dropdownVisible && (
    <div
      style={{
        position: 'absolute',
        top: '30px', // Adjust position based on your layout
        right: '-30px', // Adjusted to move it further to the right
        zIndex: 1,
        backgroundColor: '#e3f2fd', // Light bluish shade
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '0', // Remove extra padding here
      }}
    >
      <div
        onClick={() => handleDropdownChange({ target: { value: 'practiceCard' } })}
        style={{
          cursor: 'pointer',
          padding: '10px 15px', // Increased padding for better coverage
          transition: 'background-color 0.3s ease',
          whiteSpace: 'nowrap', // Prevent text from wrapping
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
      >
        Practice FlashCard
      </div>
      <div
        onClick={() => handleDropdownChange({ target: { value: 'spacedrep' } })}
        style={{
          cursor: 'pointer',
          padding: '10px 15px', // Increased padding for better coverage
          transition: 'background-color 0.3s ease',
          whiteSpace: 'nowrap', // Prevent text from wrapping
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
      >
        Practice with Spaced Repetition
      </div>
    </div>
  )}
</div> </div> 
      <div className="mb-4">
  <label className="block text-gray-700 font-semibold">Question:</label>
  <ReactQuill
    ref={questionQuillRef}
    value={currentQuestion}
    onChange={setCurrentQuestion}
    placeholder="Enter your question here"
    theme="snow"
    // onKeyDown={handleKeyDown}
    onContextMenu={(e) => handleContextMenu(e, 'question')}
    modules={{
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }], // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
        [{ 'direction': 'rtl' }], // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'], // remove formatting button
      ],
    }}
    formats={[
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
      'list', 'bullet', 'indent',
      'link', 'image', 'video',
      'color', 'background', 'align',
      'script',
    ]}
    style={{
      wordBreak: 'break-all', // Forces breaking of long words
      overflowWrap: 'anywhere', // Allows breaking words even without spaces
      whiteSpace: 'pre-wrap', // Preserves newlines and spaces
      maxWidth: '100%', // Ensures the content stays within its container
    }}
  />
  <div className="mt-2" style={{ wordBreak: 'break-all', overflowWrap: 'anywhere' }}>
    {renderContent(currentQuestion)}
  </div>

  <label className="block text-gray-700 font-semibold mt-4">Answer:</label>
  <Select
    value={currentAnswer}
    onChange={(e) => setCurrentAnswer(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
    // onKeyDown={handleKeyDown}
    displayEmpty
    style={{
      wordBreak: 'break-all', // Forces breaking of long words
      overflowWrap: 'anywhere', // Allows breaking words even without spaces
    }}
  >
    <MenuItem value="">
      <em>Select</em>
    </MenuItem>
    <MenuItem value="True">True</MenuItem>
    <MenuItem value="False">False</MenuItem>
  </Select>
</div>

<div className="mb-4">
  <label className="block text-gray-700 font-semibold">Explanation:</label>
  <ReactQuill
    ref={explanationQuillRef}
    value={currentExplanation}
    onChange={setCurrentExplanation}
    placeholder="Enter your explanation here"
    theme="snow"
    // onKeyDown={handleKeyDown}
    onContextMenu={(e) => handleContextMenu(e, 'explanation')}
    modules={{
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }], // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
        [{ 'direction': 'rtl' }], // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'], // remove formatting button
      ],
    }}
    formats={[
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
      'list', 'bullet', 'indent',
      'link', 'image', 'video',
      'color', 'background', 'align',
      'script',
    ]}
    className="quill-editor"
    style={{
      wordBreak: 'break-all',  // Breaks long words at any point
      overflowWrap: 'anywhere', // Allows breaking even without spaces
      whiteSpace: 'pre-wrap',   // Preserves line breaks and whitespace
      maxWidth: '100%',         // Ensures content fits within the container
    }}
  />
  <div className="mt-2" style={{ wordBreak: 'break-all', overflowWrap: 'anywhere' }}>
    {renderContent(currentExplanation)}
  </div>
</div>
<Button 
  onClick={handleApiCall} 
  variant="contained" 
  sx={{ backgroundColor: '#1565c0', color: 'white', '&:hover': { backgroundColor: '#0d47a1' } }}
>
  add 
</Button>

      <div>
        <ul className="card-list">
          {cards.map((card, index) => (
            <li
              key={index}
              className="card"
              style={{ 
                background: 'linear-gradient(to right, #dfe6f9, rgb(124 182 246))',
                position: 'relative',
                padding: '16px',
                borderRadius: '8px',
                marginBottom: '12px',
                wordBreak: 'break-word',
                overflowWrap: 'break-word'
              }}
            >
              <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            paddingRight: '40px', // Adjust padding if needed
            maxWidth: 'calc(100% - 70px)',
            whiteSpace: 'pre-wrap'
          }}
        >
          <strong style={{ marginRight: '2px' }}>Q:</strong>
          <span style={{ flex: 1 }}><strong>{renderContent(card.question)}</strong></span>
        </div>

              <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '10px' }}>
                <i className={`bi ${card.expanded ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand'}`} title={card.expanded ? 'Contract' : 'Expand'} onClick={() => toggleCardExpand(index)} style={{ cursor: 'pointer' }}></i>
                <i className="bi bi-pencil" title="Edit" onClick={() => openEditModal(index)} style={{ cursor: 'pointer' }}></i>
                <i className="bi bi-trash" title="Delete" onClick={() => openDeleteDialog(index)} style={{ cursor: 'pointer' }}></i>
                <i className="bi bi-tag" title="Tag" onClick={(e) => openTagModal(index, e)} style={{ cursor: 'pointer' }}></i>
              </div>
              {card.expanded && (
                <>
                 <div style={{ display: 'flex', alignItems: 'center' }}>
      <strong>A:</strong>
      <span style={{ marginLeft: '5px' }}>{card.answer ? 'True' : 'False'}</span>
    </div>
    {card.explanation && (
                  <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
                  }}
                >
                   <strong style={{ marginRight: '5px' }}>Explanation:</strong>
                   <span style={{ flex: 1 }}>{renderContent(card.explanation)}</span>
                  </div>
                  )}
                  <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    <p>
                      Tags:
                      {card.tags.map((tag, idx) => (
                        <span key={tag.id}>
                          [{tag.name}{idx < card.tags.length - 1 ? ', ' : ''}]
                        </span>
                      ))}
                    </p>
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Edit Modal */}
      <Dialog open={editModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Edit Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit your flashcard details below.</DialogContentText>
          <label className="block text-gray-700 font-semibold mt-4">Question:</label>
          <ReactQuill
            value={editQuestion}
            onChange={setEditQuestion}
            placeholder="Enter your question here"
            theme="snow"
            style={{ marginBottom: '16px' }}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
              ]
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
              'list', 'bullet', 'indent',
              'link', 'image', 'video',
              'color', 'background', 'align',
              'script'
            ]}
          />

          <FormControl variant="outlined" fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel id="edit-answer-label">Answer</InputLabel>
            <Select
              labelId="edit-answer-label"
              value={editAnswer}
              onChange={(e) => setEditAnswer(e.target.value)}
              label="Answer"
            >
              <MenuItem value="True">True</MenuItem>
              <MenuItem value="False">False</MenuItem>
            </Select>
          </FormControl>

          <label className="block text-gray-700 font-semibold">Explanation:</label>
          <ReactQuill
            value={editExplanation}
            onChange={setEditExplanation}
            placeholder="Enter your explanation here"
            theme="snow"
            style={{ marginBottom: '16px' }}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
              ]
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
              'list', 'bullet', 'indent',
              'link', 'image', 'video',
              'color', 'background', 'align',
              'script'
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleUpdateCard} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this flashcard?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteCard}>Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Tag Modal */}
      <TagModal
        open={tagModalOpen}
        position={tagModalPosition}
        onClose={() => setTagModalOpen(false)}
        subfolderId={subfolder_id}
        cardId={cards[tagCardIndex]?.id}
        cardQuestionType={cards[tagCardIndex]?.question_type}
        onTagAdded={handleTagAdded}
      />

      {/* Popover for Editing Selected Text */}
      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '16px', width: '300px' }}>
          <ReactQuill 
            value={popoverContent} 
            onChange={handlePopoverChange} 
            theme="snow" 
            style={{ height: '100px', marginBottom: '8px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <Button onClick={handlePopoverClose} color="secondary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handlePopoverSave} color="primary" variant="contained">
              Save
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default TrueFalse;
